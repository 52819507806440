import { getRouteWithRedirect } from '@/services/route-redirection';
import { SessionManager } from '@/services/session-manager';
import { FC, ReactNode, useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';

const AuthProtectedPageElement: FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [isAuth, setAuth] = useState<boolean | null>(null);

  useEffect(() => {
    setAuth(SessionManager.getUser() !== null);
    return SessionManager.onUserChange(x => setAuth(x !== null));
  }, []);

  if (isAuth === null) {
    return null;
  }

  if (!isAuth) {
    const route = getRouteWithRedirect('/login');
    return <Navigate to={route} replace />;
  }

  return <>{children}</>;
};

export const withAuthProtection = (Page: FC) => {
  const Protected: FC = () => (
    <AuthProtectedPageElement>
      <Page />
    </AuthProtectedPageElement>
  );

  Protected.displayName = `PageWithAuthProtection(${Page.displayName || Page.name || 'Component'})`;
  return Protected;
};
