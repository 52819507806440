import { AxiosError } from 'axios';

export const isAxiosError = (error: Error) => {
  return (error as AxiosError).isAxiosError;
};

export const isAuthError = (error: Error) => {
  return isAxiosError(error) && (error as AxiosError).response?.status === 401;
};

export const getAuthErrorReason = (error: Error): string | null => {
  return isAuthError(error)
    ? ((error as AxiosError).response?.data as any)?.reason ?? null
    : null;
};

export const isUserRegistrationPendingAuthError = (error: Error): boolean => {
  const authErrorReason = getAuthErrorReason(error);
  return authErrorReason === 'User is not registered.';
};
