import { FC } from 'react';
import { Link } from 'react-router-dom';

const NotFoundPage: FC = () => {
  return (
    <div>
      <h2>Nothing to see here!</h2>
      <p className="underline">
        <Link to="/">Go to the home page</Link>
      </p>
    </div>
  );
};

export default NotFoundPage;
