import ShIconButton from '@/components/ui-framework/ShIconButton/ShIconButton';
import { ToastService } from '@/components/ui-framework/ShToast/services/toast-service';
import { eventService } from '@/services/api-clients/event-service';
import { DetailedEvent } from '@/services/api-clients/models/detailed-event';
import { copyEventLink, isShareSupported } from '@/services/share-service';
import dayjs from 'dayjs';
import { CalendarPlusIcon, LinkIcon } from 'lucide-react';
import { FC, useState } from 'react';
import './EventCalendarSection.scss';

interface Props {
  event: DetailedEvent;
}

const EventCalendarSection: FC<Props> = props => {
  const [hasPassed] = useState(dayjs(props.event.eventAt).isBefore(dayjs()));

  const getEventDay = () => dayjs(props.event.eventAt).format('dddd, MMMM D');

  const getEventTime = () => dayjs(props.event.eventAt).format('h:mma');

  const getEventDuration = () => (props.event.duration / 60).toFixed(1) + 'h';

  const downloadIcs = async () => {
    await eventService.downloadIcs(props.event.id);
  };

  const copyLink = async () => {
    ToastService.show('Event link copied!');
    await copyEventLink(props.event.id);
  };

  const getUserTimeZone = () => {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
  };

  return (
    <div className="event-calendar-section-root">
      <div className="event-calendar-section-content">
        <div className="event-calendar-section-day">🗓 {getEventDay()}</div>
        <div className="event-calendar-section-time">
          @{getEventTime()} • {getEventDuration()}
        </div>
        <div className="event-calendar-section-timezone">
          {getUserTimeZone()}
        </div>
      </div>
      <div className="event-calendar-section-actions">
        {!hasPassed && (
          <ShIconButton
            title="Download calendar event"
            variant="dark"
            onClick={() => downloadIcs()}
          >
            <CalendarPlusIcon />
          </ShIconButton>
        )}
        {isShareSupported() && (
          <ShIconButton
            title="Copy link"
            variant="dark"
            onClick={() => copyLink()}
          >
            <LinkIcon />
          </ShIconButton>
        )}
      </div>
    </div>
  );
};

export default EventCalendarSection;
