import { GamingPlatform } from '@/services/api-clients/models/gaming-platform';
import { ReactNode } from 'react';
import { ReactComponent as NintendoSwitchIcon } from './icons/nintendo-switch.svg';
import { ReactComponent as PCIcon } from './icons/pc.svg';
import { ReactComponent as PS3Icon } from './icons/ps3.svg';
import { ReactComponent as PS4Icon } from './icons/ps4.svg';
import { ReactComponent as PS5Icon } from './icons/ps5.svg';
import { ReactComponent as WiiIcon } from './icons/wii.svg';
import { ReactComponent as WiiUIcon } from './icons/wiiu.svg';
import { ReactComponent as XboxOneIcon } from './icons/xbox-one.svg';
import { ReactComponent as XboxXSIcon } from './icons/xbox-sx.svg';
import { ReactComponent as XboxIcon } from './icons/xbox.svg';

const GamingPlatformIcons = {
  [GamingPlatform.PC]: PCIcon,
  [GamingPlatform.PlayStation3]: PS3Icon,
  [GamingPlatform.PlayStation4]: PS4Icon,
  [GamingPlatform.PlayStation5]: PS5Icon,
  [GamingPlatform.Wii]: WiiIcon,
  [GamingPlatform.WiiU]: WiiUIcon,
  [GamingPlatform.NintendoSwitch]: NintendoSwitchIcon,
  [GamingPlatform.Xbox360]: XboxIcon,
  [GamingPlatform.XboxOne]: XboxOneIcon,
  [GamingPlatform.XboxSeriesXS]: XboxXSIcon,
};

const GamingPlatformLabels: { [platform in GamingPlatform]: string } = {
  [GamingPlatform.PC]: 'PC',
  [GamingPlatform.PlayStation3]: 'PlayStation 3',
  [GamingPlatform.PlayStation4]: 'PlayStation 4',
  [GamingPlatform.PlayStation5]: 'PlayStation 5',
  [GamingPlatform.Wii]: 'Wii',
  [GamingPlatform.WiiU]: 'Wii U',
  [GamingPlatform.NintendoSwitch]: 'Nintendo Switch',
  [GamingPlatform.Xbox360]: 'Xbox 360',
  [GamingPlatform.XboxOne]: 'Xbox One',
  [GamingPlatform.XboxSeriesXS]: 'Xbox Series X/S',
};

export const getGamingPlatformIcon = (
  platform: GamingPlatform
): ReactNode | null => {
  const Icon = GamingPlatformIcons[platform];
  if (!Icon) {
    console.warn(`No icon for platform ${platform}`);
  }
  return Icon ? <Icon /> : null;
};

export const getGamingPlatformLabel = (
  platform: GamingPlatform
): string | null => {
  const label = GamingPlatformLabels[platform];
  if (!label) {
    console.warn(`No label for platform ${platform}`);
  }
  return label || null;
};
