import LoadingOverlay from '@/components/Kernel/Common/LoadingOverlay/LoadingOverlay';
import { useAuthentication } from '@/hooks/use-authentication';
import { analyticsService } from '@/services/analytics-service';
import { eventService } from '@/services/api-clients/event-service';
import { GamingPlatform } from '@/services/api-clients/models/gaming-platform';
import { getRouteWithRedirect } from '@/services/route-redirection';
import { TrackingEvent } from '@/services/tracking-event';
import { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import DateTimeSelector from './components/DateTimeSelector/DateTimeSelector';
import DurationSelector from './components/DurationSelector/DurationSelector';
import EventEditorOverview from './components/EventEditorOverview/EventEditorOverview';
import GameSelector from './components/GameSelector/GameSelector';
import GamingPlatformSelector from './components/GamingPlatformSelector/GamingPlatformSelector';
import GiphySelector from './components/GiphySelector/GiphySelector';
import './EventEditorPage.scss';
import { useEventCreationData } from './hooks/use-event-creation-data';
import { useExistingEvent } from './hooks/use-existing-event';

const EventEditorPage: FC = () => {
  const navigate = useNavigate();
  const auth = useAuthentication();
  const [loading, setLoading] = useState(false);
  const [isGameSelectorOpen, openGameSelector] = useState(false);
  const [isGiphySelectorOpen, openGiphySelector] = useState(false);
  const [isDateTimeSelectorOpen, openDateTimeSelector] = useState(false);
  const [isDurationSelectorOpen, openDurationSelector] = useState(false);
  const [isPlatformSelectorOpen, openPlatformSelector] = useState(false);
  const [creationData, patchCreationData, storeCreationDataFlashState] =
    useEventCreationData();
  useExistingEvent(setLoading, patchCreationData);

  const onSave = async () => {
    try {
      setLoading(true);
      if (
        !creationData.title ||
        !creationData.date ||
        !creationData.game ||
        !creationData.duration
      ) {
        throw new Error('Inconsistent EventEditorPage state');
      }

      if (!auth.user) {
        storeCreationDataFlashState();
        const route = getRouteWithRedirect('/login');
        navigate(route);
        return;
      }

      if (creationData.eventId) {
        await eventService.update({
          id: creationData.eventId,
          title: creationData.title,
          eventAt: creationData.date,
          gameId: creationData.game.id,
          duration: creationData.duration,
          platforms: creationData.platforms,
          gifUrl: creationData.gifUrl,
          description: creationData.description,
        });

        navigate(`/events/${creationData.eventId}/`);
      } else {
        const item = await eventService.create({
          title: creationData.title,
          eventAt: creationData.date,
          gameId: creationData.game.id,
          duration: creationData.duration,
          platforms: creationData.platforms,
          gifUrl: creationData.gifUrl,
          description: creationData.description,
        });
        navigate(`/events/${item.id}/`);
      }
    } finally {
      setLoading(false);
    }
  };

  const renderGameSelectorStep = () => {
    return (
      <GameSelector
        onLoadingChange={setLoading}
        onSelected={selectedGame => {
          patchCreationData({
            game: selectedGame,
            title: `${selectedGame.title} sesh`,
            description: `Let's play some ${selectedGame.title}!`,
            gifUrl: undefined,
          });
          openGameSelector(false);
        }}
      />
    );
  };

  const renderGiphySelectorStep = () => {
    return (
      <GiphySelector
        initialSearch={creationData.game?.title || ''}
        onSelected={gifUrl => {
          patchCreationData({ gifUrl });
          openGiphySelector(false);
        }}
        onCancel={() => openGiphySelector(false)}
      />
    );
  };

  const renderDateTimeSelectorStep = () => {
    return (
      <DateTimeSelector
        value={creationData.date}
        onSelected={(selectedDate: Date) => {
          patchCreationData({ date: selectedDate });
          openDateTimeSelector(false);
          analyticsService.trackEvent(TrackingEvent.SELECT_DATE, {
            eventDate: selectedDate,
          });
        }}
        onCancel={() => openDateTimeSelector(false)}
      />
    );
  };

  const renderDurationSelectorStep = () => {
    return (
      <DurationSelector
        value={creationData.duration}
        onSelected={(value: number) => {
          patchCreationData({ duration: value });
          openDurationSelector(false);
        }}
        onCancel={() => openDurationSelector(false)}
      />
    );
  };

  const renderPlatformSelectorStep = () => {
    return (
      <GamingPlatformSelector
        value={creationData.platforms}
        onSelected={(platforms: GamingPlatform[]) => {
          patchCreationData({ platforms });
          openPlatformSelector(false);
        }}
        onCancel={() => openPlatformSelector(false)}
      />
    );
  };

  const renderOverviewStep = () => {
    return (
      <EventEditorOverview
        eventCreationData={creationData}
        patchEventCreationData={patchCreationData}
        onOpenGameSelector={() => openGameSelector(true)}
        onOpenGiphySelector={() => openGiphySelector(true)}
        onOpenDateTimeSelector={() => openDateTimeSelector(true)}
        onOpenDurationSelector={() => openDurationSelector(true)}
        onOpenPlatformSelector={() => openPlatformSelector(true)}
        onSave={() => onSave()}
      />
    );
  };

  const renderEditor = () => {
    if (!creationData.game || isGameSelectorOpen) {
      return renderGameSelectorStep();
    }
    if (isGiphySelectorOpen) {
      return renderGiphySelectorStep();
    }
    if (isDateTimeSelectorOpen) {
      return renderDateTimeSelectorStep();
    }
    if (isDurationSelectorOpen) {
      return renderDurationSelectorStep();
    }
    if (isPlatformSelectorOpen) {
      return renderPlatformSelectorStep();
    }
    return renderOverviewStep();
  };

  return (
    <div className="event-editor-page-root">
      {renderEditor()}
      <LoadingOverlay loading={loading} />
    </div>
  );
};

export default EventEditorPage;
