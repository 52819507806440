import {
  isAuthError,
  isAxiosError,
  isUserRegistrationPendingAuthError,
} from '@/services/error-parser';
import { AuthStep } from '@/services/models/auth-step';
import {
  getRouteWithForwardedRedirect,
  getRouteWithRedirect,
  routeHasRedirect,
} from '@/services/route-redirection';
import { AxiosError } from 'axios';

export interface MappedError {
  message?: string;
  redirect?: string;
  logout?: boolean;
  setAuthStep?: AuthStep;
  silent: boolean;
}

export const mapError = (error: Error): MappedError => {
  if (isAxiosError(error)) {
    const axiosError = error as AxiosError;

    if (
      axiosError.code === 'ERR_NETWORK' ||
      axiosError.code === 'ECONNABORTED' ||
      axiosError.code === 'ETIMEDOUT'
    ) {
      return {
        message: 'There was a network error. Is your internet connection ok?',
        silent: false,
      };
    }

    if (isUserRegistrationPendingAuthError(axiosError)) {
      const redirect = routeHasRedirect()
        ? getRouteWithForwardedRedirect('/register')
        : getRouteWithRedirect('/register');
      return {
        redirect,
        silent: true,
        setAuthStep: AuthStep.WaitingForRegistration,
      };
    }

    if (isAuthError(axiosError)) {
      const redirect = getRouteWithRedirect('/login');
      return {
        redirect,
        silent: true,
        logout: true,
      };
    }

    const serverError = (axiosError.response?.data as any)?.message;
    if (serverError) {
      return {
        message: serverError,
        silent: false,
      };
    }
  }

  if (error.name === 'NotSupportedYetError') {
    return {
      message: 'This feature is not supported yet.',
      silent: false,
    };
  }

  if (error.name === 'ShareNotSupportedError') {
    return {
      message: 'Share is not supported in this browser.',
      silent: false,
    };
  }

  return { silent: true };
};
