import { DependencyList, EffectCallback, useEffect } from 'react';

export const useThrottledEffect = (
  callback: EffectCallback,
  delay: number,
  dependencies?: DependencyList
) => {
  useEffect(() => {
    const handler = setTimeout(() => {
      callback();
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, dependencies);
};
