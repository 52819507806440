import EventPoster from '@/components/Kernel/Common/EventPoster/EventPoster';
import GamingPlatformsSection from '@/components/Kernel/Common/GamingPlatformsSection/GamingPlatformsSection';
import StickySection from '@/components/Kernel/Common/StickySection/StickySection';
import ShButton from '@/components/ui-framework/ShButton/ShButton';
import ShInput from '@/components/ui-framework/ShInput/ShInput';
import ShTextArea from '@/components/ui-framework/ShTextArea/ShTextArea';
import dayjs from 'dayjs';
import { CalendarIcon, JoystickIcon, ShellIcon, TimerIcon } from 'lucide-react';
import { FC } from 'react';
import { EventCreationData } from '../../models/event-creation-data';
import './EventEditorOverview.scss';

interface Props {
  eventCreationData: EventCreationData;
  onSave: () => void;
  onOpenGameSelector: () => void;
  onOpenGiphySelector: () => void;
  onOpenDateTimeSelector: () => void;
  onOpenDurationSelector: () => void;
  onOpenPlatformSelector: () => void;
  patchEventCreationData: (patch: Partial<EventCreationData>) => void;
}

const EventEditorOverview: FC<Props> = ({
  onSave,
  eventCreationData,
  onOpenGameSelector,
  onOpenGiphySelector,
  onOpenDateTimeSelector,
  onOpenDurationSelector,
  onOpenPlatformSelector,
  patchEventCreationData,
}) => {
  const isValid = () => {
    return (
      !!eventCreationData.title &&
      eventCreationData.date !== null &&
      eventCreationData.game !== null &&
      eventCreationData.duration > 0
    );
  };

  const renderDateTimeButton = () => {
    return (
      <ShButton justify="start" onClick={() => onOpenDateTimeSelector()}>
        {!eventCreationData.date && (
          <>
            <CalendarIcon /> Pick a date and time
          </>
        )}
        {!!eventCreationData.date && (
          <>
            <CalendarIcon />
            {dayjs(eventCreationData.date).format('DD/MM/YYYY hh:mm A')}
          </>
        )}
      </ShButton>
    );
  };

  const renderDurationButton = () => {
    return (
      <ShButton justify="start" onClick={() => onOpenDurationSelector()}>
        {eventCreationData.duration <= 0 && (
          <>
            <TimerIcon /> Pick a duration
          </>
        )}
        {eventCreationData.duration > 0 && (
          <>
            <TimerIcon />
            Duration: {eventCreationData.duration / 60}h
          </>
        )}
      </ShButton>
    );
  };

  const renderPlatformButton = () => {
    if (eventCreationData.platforms.length === 0) {
      return (
        <ShButton justify="start" onClick={() => onOpenPlatformSelector()}>
          <JoystickIcon /> Select Platforms
        </ShButton>
      );
    }
    return (
      <div
        className="event-editor-overview-platforms"
        onClick={() => onOpenPlatformSelector()}
      >
        <GamingPlatformsSection
          size="md"
          values={eventCreationData.platforms}
        />
      </div>
    );
  };

  const renderSaveButton = () => {
    return (
      <div className="event-editor-overview-save">
        <ShButton
          variant="accent"
          disabled={!isValid()}
          onClick={() => onSave()}
        >
          {eventCreationData.eventId ? 'Edit' : 'Create'}
          <ShellIcon />
        </ShButton>
      </div>
    );
  };

  const renderTitle = () => {
    return (
      <ShInput
        placeholder="Title"
        value={eventCreationData.title || ''}
        onChange={event => {
          patchEventCreationData({ title: event.target.value });
        }}
      />
    );
  };

  const renderDescription = () => {
    return (
      <ShTextArea
        placeholder="Description"
        value={eventCreationData.description || ''}
        onChange={event => {
          patchEventCreationData({ description: event.target.value });
        }}
      />
    );
  };

  return (
    <div className="event-editor-overview-root">
      <EventPoster
        editorMode={{
          onChangeGame: () => onOpenGameSelector(),
          onAddGif: () => onOpenGiphySelector(),
          onRemoveGif: () => patchEventCreationData({ gifUrl: undefined }),
        }}
        gifUrl={eventCreationData.gifUrl}
        imageUrl={eventCreationData.game?.imageUrl}
      />
      {renderDateTimeButton()}
      {renderDurationButton()}
      {renderTitle()}
      {renderDescription()}
      {renderPlatformButton()}
      <StickySection>{renderSaveButton()}</StickySection>
    </div>
  );
};

export default EventEditorOverview;
