import { FC, useEffect, useRef, useState } from 'react';
import { RecaptchaHandler } from './services/recaptcha-handler';

const Recaptcha: FC = () => {
  const divRef = useRef<HTMLDivElement>(null);
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    if (divRef.current && !initialized) {
      RecaptchaHandler.initialize(divRef.current.id);
      setInitialized(true);
    }
  }, [divRef]);

  useEffect(() => {
    return () => {
      if (initialized) {
        RecaptchaHandler.clear();
      }
    };
  }, [initialized]);

  return <div ref={divRef} id="recaptcha-container"></div>;
};

export default Recaptcha;
