import { DetailedEvent } from '@/services/api-clients/models/detailed-event';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';

const MinutesToStartThreshold = 5;

export interface EventTimeWindow {
  isClosed: boolean | null;
  hasStarted: boolean | null;
  inProgress: boolean | null;
  minutesToStart: number | null;
  isAboutToStart: boolean | null;
}

export const useEventTimeWindow = (
  event: DetailedEvent | null
): EventTimeWindow => {
  const [isClosed, setClosed] = useState<boolean | null>(null);
  const [hasStarted, setStarted] = useState<boolean | null>(null);
  const [inProgress, setInProgress] = useState<boolean | null>(null);
  const [isAboutToStart, setAboutToStart] = useState<boolean | null>(null);
  const [minutesToStart, setMinutesToStart] = useState<number | null>(null);

  useEffect(() => {
    if (!event) {
      return;
    }
    let timer: NodeJS.Timeout | null = null;

    const update = () => {
      const now = dayjs().set('second', 0).set('millisecond', 0);
      const eventAt = dayjs(event.eventAt);
      const closeAt = eventAt.add(event.duration, 'minutes');
      const started = now.isAfter(eventAt) || now.isSame(eventAt);
      const closed = now.isAfter(closeAt) || now.isSame(closeAt);
      const inProgress = started && !closed;

      setStarted(started);
      setClosed(closed);
      setInProgress(inProgress);

      const minutesToStart = eventAt.diff(now, 'minutes');

      if (minutesToStart > 0 && minutesToStart <= MinutesToStartThreshold) {
        setAboutToStart(true);
        setMinutesToStart(minutesToStart);
      } else {
        setAboutToStart(false);
        setMinutesToStart(-1);
      }

      if (!closed) {
        const now = new Date();
        const msUntilNextMinute = (60 - now.getSeconds()) * 1000;

        // TODO: Remove after testing
        console.info('scheduling useEventTimeWindow timer for next minute');
        timer = setTimeout(() => update(), msUntilNextMinute);
      }
    };

    update();

    return () => {
      if (timer) {
        // TODO: Remove after testing
        console.info('clearing useEventTimeWindow timer');
        clearTimeout(timer);
      }
    };
  }, [event]);

  return {
    isClosed,
    hasStarted,
    inProgress,
    minutesToStart,
    isAboutToStart,
  };
};
