import { getCurrentUserToken } from '../firebase';
import { WsClient } from './ws-client';

export class BearerTokenWsClient extends WsClient {
  public constructor(
    private _tokenLoader: () => Promise<string | null> | null
  ) {
    super();
  }

  protected async getAuthentication(): Promise<{
    token: string;
    type: string;
  } | null> {
    const token = await this._tokenLoader();
    return token
      ? {
          type: 'bearer',
          token,
        }
      : null;
  }
}

export const bearerTokenWsClient = new BearerTokenWsClient(async () => {
  return await getCurrentUserToken();
});
