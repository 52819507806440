import { Tooltip } from '@mantine/core';
import { FC, PropsWithChildren } from 'react';

interface Props {
  value?: string;
}

const ShTooltip: FC<PropsWithChildren<Props>> = props => {
  return (
    <Tooltip
      label={props.value}
      events={{ hover: true, focus: true, touch: true }}
    >
      {props.children}
    </Tooltip>
  );
};

export default ShTooltip;
