export const getRouteWithRedirect = (route: string) => {
  const currentLocation = window.location;
  let redirect = currentLocation.pathname + currentLocation.search;
  if (redirect === '/profile') {
    redirect = '/';
  }
  const encodedRedirect = encodeURIComponent(redirect);
  return `${route}?redirect=${encodedRedirect}`;
};

export const getRedirectInRoute = (defaultValue = '/') => {
  const currentLocation = window.location;
  const queryParams = new URLSearchParams(currentLocation.search);
  const redirect = queryParams.get('redirect');
  return redirect ? decodeURIComponent(redirect) : defaultValue;
};

export const routeHasRedirect = () => {
  const currentLocation = window.location;
  const queryParams = new URLSearchParams(currentLocation.search);
  return queryParams.get('redirect') !== null;
};

export const getRouteWithForwardedRedirect = (
  route: string,
  defaultRoute = '/'
) => {
  const redirect = getRedirectInRoute(defaultRoute);
  const encodedRedirect = encodeURIComponent(redirect);
  return `${route}?redirect=${encodedRedirect}`;
};
