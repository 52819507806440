import { ShareNotSupportedError } from '@/errors/share-not-supported-error';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import { analyticsService } from './analytics-service';
import { DetailedEvent } from './api-clients/models/detailed-event';
import { configuration } from './configuration';
import { TrackingEvent } from './tracking-event';

const _getEventLandingPageUrl = (eventId: string) => {
  return `${configuration.eventsApi.shareUrl}/${eventId}`;
};

const _shareUrl = async (title: string, text: string, url: string) => {
  if (navigator.share) {
    await navigator.share({
      title,
      text,
      url,
    });
  } else {
    throw new ShareNotSupportedError();
  }
};

export const shareEvent = async (event: DetailedEvent) => {
  dayjs.extend(advancedFormat);
  const eventDate = dayjs(event.eventAt).format('dddd, MMMM Do [at] h:mma');
  await _shareUrl(
    event.title,
    `Join "${event.title}" event to play "${event.game.title}" on ${eventDate}\n`,
    _getEventLandingPageUrl(event.id)
  );
  analyticsService.trackEvent(TrackingEvent.SHARE_EVENT_LINK, {
    event,
  });
};

export const copyEventLink = async (eventId: string) => {
  await navigator.clipboard.writeText(_getEventLandingPageUrl(eventId));
  analyticsService.trackEvent(TrackingEvent.COPY_EVENT_LINK, {
    eventId,
  });
};

export const isShareSupported = () => {
  return !!navigator.share;
};
