export interface LayoutConfig {
  backgroundImage: string | null;
  colorMode: 'light' | 'dark';
}

export class LayoutService {
  private static _config: LayoutConfig | null = null;
  private static _subscribers: ((config: LayoutConfig | null) => void)[] = [];

  public static setConfig(config: LayoutConfig | null) {
    this._config = config;
    this._subscribers.forEach(cb => cb(config));
  }

  public static getConfig() {
    return this._config;
  }

  public static subscribe(
    callback: (backgroundImage: LayoutConfig | null) => void
  ) {
    this._subscribers.push(callback);
    return () => {
      this._subscribers = this._subscribers.filter(
        subscriber => subscriber !== callback
      );
    };
  }
}
