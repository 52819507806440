import { useEffect, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import TopBar from './components/TopBar/TopBar';
import './MainLayout.scss';
import { LayoutConfig, LayoutService } from './services/layout-service';

export const MainLayout = () => {
  const location = useLocation();
  const [config, setConfig] = useState<LayoutConfig | null>(
    LayoutService.getConfig()
  );

  useEffect(() => {
    return LayoutService.subscribe(setConfig);
  }, []);

  useEffect(() => {
    LayoutService.setConfig(null);
  }, [location.pathname]);

  return (
    <div className="main-layout-root">
      {config?.backgroundImage && (
        <div className="main-layout-background-wrapper">
          <div
            className="main-layout-background"
            style={{ backgroundImage: `url(${config.backgroundImage})` }}
          ></div>
        </div>
      )}
      <TopBar colorMode={config?.colorMode || 'light'} />
      <div className="main-layout-content">
        <Outlet />
      </div>
    </div>
  );
};
