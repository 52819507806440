import { User } from '@/services/api-clients/models/user';
import { configuration } from '@/services/configuration';

class AnalyticsService {
  private _context = {
    app: {
      name: 'spa',
      version: configuration.version,
      environment: configuration.environment,
    },
  };

  private get _analytics(): any {
    const wnd = window as any;
    const analytics = wnd['analytics'];
    if (!analytics) {
      if (configuration.environment !== 'local-dev') {
        console.warn('Analytics API "window.analytics" not found');
      }

      return null;
    }
    return analytics;
  }

  public identify(user: User) {
    this._analytics?.identify?.(user.id, {
      avatar: user.avatar,
      username: user.username,
      context: this._context,
    });
  }

  public reset() {
    this._analytics?.reset?.();
  }

  public trackEvent(event: string, properties: any = {}): void {
    this._analytics?.track?.(event, properties, {
      context: this._context,
    });
  }

  public trackPage(): void {
    this._analytics?.page?.(undefined, undefined, undefined, {
      context: this._context,
    });
  }
}

export const analyticsService = new AnalyticsService();
