import StickySection from '@/components/Kernel/Common/StickySection/StickySection';
import ShButton from '@/components/ui-framework/ShButton/ShButton';
import ShLabel from '@/components/ui-framework/ShLabel/ShLabel';
import { Calendar, TimeInput } from '@mantine/dates';
import dayjs from 'dayjs';
import { FC, useEffect, useRef, useState } from 'react';
import './DateTimeSelector.scss';

interface Props {
  value: Date | null;
  onSelected: (date: Date) => void;
  onCancel: () => void;
}

const DateTimeSelector: FC<Props> = ({ onSelected, onCancel, value }) => {
  const timeInputRef = useRef<HTMLInputElement>(null);
  const [selectedDate, setSelectedDate] = useState<Date | null>(value);
  const [selectedTime, setSelectedTime] = useState<string | null>(
    value ? dayjs(value).format('HH:mm') : null
  );
  const [selectedDateTime, setSelectedDateTime] = useState<Date | null>(null);
  const [isValid, setValid] = useState(false);

  useEffect(() => {
    if (!selectedDate || !selectedTime) {
      return;
    }
    const [hours, minutes] = selectedTime.split(':');
    const value = new Date(selectedDate.getTime());
    value.setHours(parseInt(hours, 10));
    value.setMinutes(parseInt(minutes, 10));
    setSelectedDateTime(value);
  }, [selectedDate, selectedTime]);

  useEffect(() => {
    setValid(
      !!selectedDateTime && selectedDateTime.getTime() > new Date().getTime()
    );
  }, [selectedDateTime]);

  const accept = () => {
    if (!selectedDateTime) {
      return;
    }
    onSelected(selectedDateTime);
  };

  const cancel = () => onCancel();

  const getSelectedDateTimeAsString = () => {
    if (!isValid) {
      return 'Make a selection to continue';
    } else {
      return `Confirm ${dayjs(selectedDateTime).format('ddd MM/DD • hh:mma')}`;
    }
  };

  return (
    <div className="date-selector-root">
      <ShLabel>Select date and time</ShLabel>
      <Calendar
        className="date-selector-calendar"
        firstDayOfWeek={0}
        minDate={new Date()}
        renderDay={(date: Date) => {
          return dayjs(date).isSame(selectedDate, 'date') ? (
            <div className="selected">{dayjs(date).format('D')}</div>
          ) : (
            <div>{dayjs(date).format('D')}</div>
          );
        }}
        getDayProps={(date: Date) => ({
          selected: dayjs(date).isSame(selectedDate, 'date'),
          onClick: () => {
            setSelectedDate(date);
            if (!selectedTime) {
              setSelectedTime(
                dayjs(new Date().getTime() + 2 * 60 * 60 * 1000).format('HH:mm')
              );
            }
          },
        })}
      />
      <TimeInput
        className="date-selector-time-input"
        ref={timeInputRef}
        value={selectedTime || ''}
        onClick={() => timeInputRef.current?.showPicker()}
        onChange={e => setSelectedTime(e.target.value)}
        size="lg"
      />
      <StickySection>
        <ShButton variant="dark" disabled={!isValid} onClick={() => accept()}>
          {getSelectedDateTimeAsString()}
        </ShButton>
        <ShButton onClick={() => cancel()}>Cancel</ShButton>
      </StickySection>
    </div>
  );
};

export default DateTimeSelector;
