import ShAvatar from '@/components/ui-framework/ShAvatar/ShAvatar';
import { DetailedEvent } from '@/services/api-clients/models/detailed-event';
import { FC } from 'react';
import './EventHostSection.scss';

interface Props {
  event: DetailedEvent;
}

const EventHostSection: FC<Props> = props => {
  return (
    <div className="event-host-section-root">
      Hosted by <ShAvatar value={props.event.createdBy.avatar} size="sm" />
      <div className="event-host-section-username">
        {props.event.createdBy.username}
      </div>
    </div>
  );
};

export default EventHostSection;
