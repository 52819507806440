import ShAvatar from '@/components/ui-framework/ShAvatar/ShAvatar';
import { DetailedEventComment } from '@/services/api-clients/models/detailed-event-comment';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { FC } from 'react';
import './EventCommentCard.scss';

dayjs.extend(relativeTime);

interface Props {
  comment: DetailedEventComment;
}

const EventCommentsCard: FC<Props> = props => {
  return (
    <div className="event-comment-card-root">
      <div className="event-comment-card-header">
        <div className="event-comment-card-header-left">
          <ShAvatar size="sm" value={props.comment.avatar} />
          <div className="event-comment-card-username">
            {props.comment.username}
          </div>
        </div>

        <div className="event-comment-card-header-time">
          {dayjs(props.comment.date).fromNow()}
        </div>
      </div>
      <div className="event-comment-card-message">{props.comment.message}</div>
    </div>
  );
};

export default EventCommentsCard;
