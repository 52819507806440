import ShTooltip from '@/components/ui-framework/ShTooltip/ShTooltip';
import { GamingPlatform } from '@/services/api-clients/models/gaming-platform';
import { FC, useState } from 'react';
import {
  getGamingPlatformIcon,
  getGamingPlatformLabel,
} from '../GamingPlatformIcons/GamingPlatformIcons';
import './GamingPlatformsSection.scss';

interface Props {
  values?: GamingPlatform[];
  size: 'sm' | 'md' | 'lg';
}

const GamingPlatformsSection: FC<Props> = props => {
  const [platforms] = useState<GamingPlatform[]>(props.values || []);

  const getSupportedIcons = () => {
    return platforms
      .filter(x => getGamingPlatformIcon(x) !== null)
      .sort((a, b) => a.localeCompare(b))
      .map(x => ({
        icon: getGamingPlatformIcon(x),
        tooltip: getGamingPlatformLabel(x) || 'Unknown',
      }));
  };

  return (
    <div className="gaming-platforms-section">
      {getSupportedIcons().map((x, index) => {
        return (
          <ShTooltip key={index} value={x.tooltip}>
            <div className={'gaming-platforms-item ' + props.size}>
              {x.icon}
            </div>
          </ShTooltip>
        );
      })}
    </div>
  );
};

export default GamingPlatformsSection;
