import { User } from './api-clients/models/user';
import { AuthStep } from './models/auth-step';

const USER_LS_KEY = 'current-user';
const AUTH_STEP_LS_KEY = 'auth-step';

export class SessionManager {
  private static _subscriptions: {
    [key: string]: ((value: any) => void)[];
  } = {};

  private static _set<T>(key: string, value: T) {
    localStorage.setItem(key, JSON.stringify(value));
  }

  private static _get<T>(key: string): T | null {
    const value = localStorage.getItem(key);
    return value ? JSON.parse(value) : null;
  }

  private static _subscribe<T>(
    key: string,
    callback: (value: T | null) => void
  ) {
    if (!this._subscriptions[key]) {
      this._subscriptions[key] = [];
    }
    this._subscriptions[key].push(callback);

    return () => {
      const index = this._subscriptions[key].findIndex(cb => cb === callback);
      if (index > -1) {
        this._subscriptions[key].splice(index, 1);
      }
    };
  }

  private static _notify<T>(key: string, value: T | null) {
    if (this._subscriptions[key]) {
      this._subscriptions[key].forEach(cb => cb(value));
    }
  }

  public static setUser(user: User) {
    this._set(USER_LS_KEY, user);
    this._notify(USER_LS_KEY, user);
  }

  public static getUser(): User | null {
    return this._get<User>(USER_LS_KEY);
  }

  public static onUserChange(callback: (value: User | null) => void) {
    return this._subscribe(USER_LS_KEY, callback);
  }

  public static setAuthStep(step: AuthStep) {
    this._set(AUTH_STEP_LS_KEY, step);
    this._notify(AUTH_STEP_LS_KEY, step);
  }

  public static getAuthStep(): AuthStep {
    return this._get<AuthStep>(AUTH_STEP_LS_KEY) || AuthStep.NotStarted;
  }

  public static onAuthStepChange(callback: (value: AuthStep) => void) {
    return this._subscribe(AUTH_STEP_LS_KEY, () =>
      callback(this.getAuthStep() || AuthStep.NotStarted)
    );
  }

  public static clear() {
    localStorage.removeItem(USER_LS_KEY);
    localStorage.removeItem(AUTH_STEP_LS_KEY);
    this._notify(USER_LS_KEY, null);
    this._notify(AUTH_STEP_LS_KEY, null);
  }
}
