import ShIconButton from '@/components/ui-framework/ShIconButton/ShIconButton';
import ShLabel from '@/components/ui-framework/ShLabel/ShLabel';
import { useThrottledEffect } from '@/hooks/use-throttled-effect';
import { analyticsService } from '@/services/analytics-service';
import { gameService } from '@/services/api-clients/game-service';
import { Game } from '@/services/api-clients/models/game';
import { TrackingEvent } from '@/services/tracking-event';
import { HourglassIcon, SearchIcon, XIcon } from 'lucide-react';
import { FC, useEffect, useState } from 'react';
import './GameSelector.scss';

interface Props {
  onLoadingChange?: (loading: boolean) => void;
  onSelected: (game: Game) => void;
}

const GameSelector: FC<Props> = props => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [games, setGames] = useState<Game[]>([]);
  const [popularGames, setPopularGames] = useState<Game[]>([]);
  const [selected, setSelected] = useState<Game | null>(null);
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState('');

  useEffect(() => {
    (async () => {
      try {
        props.onLoadingChange?.(true);
        const page = await gameService.getAll(1, 5);
        setPopularGames(page.items);
      } finally {
        props.onLoadingChange?.(false);
      }
    })();
  }, []);

  useThrottledEffect(
    () => {
      (async () => {
        try {
          setLoading(true);
          const items = await gameService.search(filter);
          setGames(items);
        } finally {
          setLoading(false);
        }
      })();
    },
    300,
    [filter]
  );

  useEffect(() => {
    if (selected !== null) {
      props.onSelected(selected);
    }
  }, [selected]);

  const renderSelected = (item: Game) => {
    return (
      <div className="game-selector-selected">
        <div className="game-selector-selected-title">Selected game</div>
        <div className="list-entries">
          <div className="list-entry">
            <div
              className="list-entry-image"
              style={{ backgroundImage: 'url(' + item.imageUrl + ')' }}
            />
            <div className="list-entry-title">{item.title}</div>
            <div className="list-entry-actions">
              <ShIconButton
                onClick={e => {
                  e.preventDefault();
                  setSelected(null);
                }}
              >
                <XIcon />
              </ShIconButton>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderDropdown = () => {
    return (
      <div className="dropdown-input">
        <div className="left-icon-input">
          <div className="icon-wrapper">
            {!loading && <SearchIcon className="icon" />}
            {loading && <HourglassIcon className="icon" />}
          </div>
          <input
            className="plain"
            placeholder="Choose a game"
            value={filter}
            onChange={e => setFilter(e.target.value)}
            onFocus={() => setDropdownOpen(true)}
            onBlur={() => setTimeout(() => setDropdownOpen(false), 300)}
          />
        </div>
        {dropdownOpen && (
          <div className="dropdown-content">
            <div className="list-entries">
              {games.map(game => (
                <div
                  tabIndex={0}
                  className="list-entry"
                  key={game.id}
                  onClick={() => {
                    setSelected(game);
                    analyticsService.trackEvent(TrackingEvent.SELECT_GAME, {
                      game,
                    });
                  }}
                >
                  <div
                    className="list-entry-image"
                    style={{ backgroundImage: 'url(' + game.imageUrl + ')' }}
                  />
                  <div className="list-entry-title">{game.title}</div>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    );
  };

  const renderPopularGames = () => {
    return (
      <div className="game-selector-popular">
        <ShLabel secondary={true}>Popular games</ShLabel>
        <div className="list-entries">
          {popularGames.map(game => (
            <div
              className="list-entry"
              key={game.id}
              onClick={() => setSelected(game)}
            >
              <div
                className="list-entry-image"
                style={{ backgroundImage: 'url(' + game.imageUrl + ')' }}
              />
              <div className="list-entry-title">{game.title}</div>
            </div>
          ))}
        </div>
      </div>
    );
  };

  return (
    <div className="game-selector-root">
      {selected && renderSelected(selected)}
      {!selected && renderDropdown()}
      {!selected && !dropdownOpen && renderPopularGames()}
    </div>
  );
};

export default GameSelector;
