export enum LiveUpdatesErrorCode {
  Timeout = 1000,
  InvalidInput = 1001,
  InvalidCommand = 1002,
  UnknownSubscription = 1003,
  AccessDenied = 2000,
  Subscription = 4000,
}

export class LiveUpdatesError extends Error {
  public constructor(
    public code: LiveUpdatesErrorCode,
    message: string
  ) {
    super(message);
    Object.setPrototypeOf(this, new.target.prototype);
    this.name = LiveUpdatesError.name;
  }
}
