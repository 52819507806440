import { useEffect, useState } from 'react';
import { EventCreationData } from '../models/event-creation-data';
import { EventCreationDataFlashState } from '../services/event-creation-data-flash-state';

export const useEventCreationData = (): [
  eventCreationData: EventCreationData,
  patchEventCreationData: (patch: Partial<EventCreationData>) => void,
  storeFlashState: () => void,
] => {
  const [creationData, setCreationData] = useState<EventCreationData>({
    eventId: null,
    title: null,
    game: null,
    date: null,
    duration: 120,
    platforms: [],
  });

  const patchEventCreationData = (patch: Partial<EventCreationData>) => {
    setCreationData({
      ...creationData,
      ...patch,
    });
  };

  useEffect(() => {
    const data = EventCreationDataFlashState.unload();
    if (data) {
      setCreationData(data);
    }
  }, []);

  return [
    creationData,
    patchEventCreationData,
    () => EventCreationDataFlashState.store(creationData),
  ];
};
