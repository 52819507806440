import { FC } from 'react';
import './LoadingOverlay.scss';

interface Props {
  loading: boolean;
}

const LoadingOverlay: FC<Props> = ({ loading }) => {
  return (
    <>
      {loading && (
        <div className="loading-overlay-root">
          <div className="spinner"></div>
        </div>
      )}
    </>
  );
};

export default LoadingOverlay;
