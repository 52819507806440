import { LiveUpdatesError } from '@/errors/live-updates-error';
import WebSocket from 'isomorphic-ws';
import { configuration } from '../configuration';

export abstract class WsClient {
  public async subscribe<TArguments = any, TData = any>(
    subscription: string,
    args: TArguments,
    onData: (data: TData) => void,
    onError: (error: Error) => void
  ) {
    let socket: WebSocket;
    let reconnectTimer: NodeJS.Timeout | null = null;

    const connect = () => {
      socket = new WebSocket(this._getWebSocketUrl('live-updates'));

      socket.onclose = () => {
        const reconnectTime = 1000;
        console.warn(
          `Connection lost for subscription "${subscription}", reconnecting in ${reconnectTime} ms.`
        );
        reconnectTimer = setTimeout(connect, reconnectTime);
      };

      socket.onmessage = async (msg: any) => {
        try {
          const response = JSON.parse(msg.data.toString());

          if (response.readyToRecieveCommand) {
            const authentication = await this.getAuthentication();
            socket.send(
              JSON.stringify({
                authentication,
                subscription,
                arguments: args,
              })
            );
            return;
          }
          if (response.error && response.code) {
            onError(new LiveUpdatesError(response.code, response.error));
          } else {
            onData(response);
          }
        } catch {
          onError(
            new Error(`Failed to process message: ${msg.data.toString()}`)
          );
        }
      };

      socket.onerror = (err: any) => onError(err);
    };

    connect();

    return () => {
      if (reconnectTimer) {
        clearTimeout(reconnectTimer);
      }
      socket.onclose = () => {};
      if (socket.readyState === WebSocket.OPEN) {
        socket.close();
      }
      if (socket.readyState === WebSocket.CONNECTING) {
        socket.onopen = () => {
          socket.close();
        };
      }
    };
  }

  private _getWebSocketUrl(url: string) {
    const parsedUrl = new URL(configuration.coreApi.url);
    if (parsedUrl.protocol === 'http:') {
      parsedUrl.protocol = 'ws:';
    } else {
      parsedUrl.protocol = 'wss:';
    }
    const baseUrl = parsedUrl.toString();
    return `${baseUrl.endsWith('/') ? baseUrl : baseUrl + '/'}${
      url.startsWith('/') ? url.substring(1) : url
    }`;
  }

  protected abstract getAuthentication(): Promise<{
    token: string;
    type: string;
  } | null>;
}
