import ShButton from '@/components/ui-framework/ShButton/ShButton';
import ShInput from '@/components/ui-framework/ShInput/ShInput';
import ShLabel from '@/components/ui-framework/ShLabel/ShLabel';
import { useAuthentication } from '@/hooks/use-authentication';
import { AuthStep } from '@/services/models/auth-step';
import { getRouteWithForwardedRedirect } from '@/services/route-redirection';
import { FC, useEffect, useState } from 'react';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import { useNavigate } from 'react-router-dom';
import Header from '../../Common/Header/Header';
import LoadingOverlay from '../../Common/LoadingOverlay/LoadingOverlay';
import { withPageMetadata } from '../../Common/PageMetadata/PageMetadata';
import Recaptcha from '../../Common/Recaptcha/Recaptcha';
import StickySection from '../../Common/StickySection/StickySection';
import './LoginPage.scss';

const LoginPage: FC = () => {
  const navigate = useNavigate();
  const auth = useAuthentication();
  const [code, setCode] = useState('');
  const [phoneNumber, setPhoneNumber] = useState<string | undefined>();
  const [waitingForVerification, setWaitingForVerification] = useState(false);

  useEffect(() => {
    if (auth.step === AuthStep.WaitingForRegistration) {
      const route = getRouteWithForwardedRedirect('/register');
      navigate(route);
    } else if (auth.step === AuthStep.Completed) {
      navigate('/');
    }
  }, [auth]);

  const startLogin = async () => {
    if (phoneNumber === undefined) {
      return;
    }
    const success = await auth.login.start(phoneNumber);
    setWaitingForVerification(success);
  };

  const completeLoging = async () => {
    await auth.login.complete(code);
  };

  const enterPhoneNumberStep = () => {
    return (
      <div className="login-page-content">
        <div className="login-page-input-group deprecated-ui">
          <PhoneInput
            defaultCountry="US"
            placeholder="Enter phone number"
            value={phoneNumber}
            onChange={value => {
              setPhoneNumber(value);
            }}
          />
          <ShLabel secondary={true} size="sm">
            Message & data rates may apply.
          </ShLabel>
        </div>

        <StickySection>
          <ShButton
            variant="dark"
            upperCase={true}
            disabled={!phoneNumber}
            onClick={() => startLogin()}
          >
            Send Code
          </ShButton>
        </StickySection>
      </div>
    );
  };

  const enterVerificationCode = () => {
    return (
      <div className="login-page-content">
        <div className="login-page-input-group">
          <ShInput
            placeholder="Verification Code"
            onChange={e => setCode(e.target.value)}
            type="number"
            value={code}
          />
        </div>

        <StickySection>
          <ShButton
            variant="dark"
            upperCase={true}
            onClick={() => completeLoging()}
          >
            Verify
          </ShButton>
        </StickySection>
      </div>
    );
  };

  return (
    <div className="login-page-root ">
      {auth.errorMessage && (
        <div className="login-page-error-message">{auth.errorMessage}</div>
      )}
      <Header
        title="Join Sesh!"
        subtitle="Create an account to create a session"
        centered={true}
      />
      {!waitingForVerification && enterPhoneNumberStep()}
      {waitingForVerification && enterVerificationCode()}
      <LoadingOverlay loading={auth.loading} />
      <Recaptcha />
    </div>
  );
};

export default withPageMetadata(LoginPage, {
  title: 'Login',
});
