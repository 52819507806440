import { Avatar } from '@mantine/core';
import { FC } from 'react';
import './ShAvatar.scss';

interface Props {
  value: string;
  size: 'sm' | 'md' | 'lg';
  name?: string;
  onClick?: () => void;
}

const ShAvatar: FC<Props> = props => {
  const getMappedSize = () => {
    const mappedSize = {
      sm: '1.8rem',
      md: '3rem',
      lg: '10rem',
    };
    return mappedSize[props.size] || mappedSize['md'];
  };

  return (
    <div className="sh-avatar">
      <Avatar
        onClick={() => props.onClick?.()}
        radius="100%"
        size={getMappedSize()}
        src={props.value}
        className="sh-avatar-image"
      />
      {props.name && <div className="sh-avatar-name">{props.name}</div>}
    </div>
  );
};

export default ShAvatar;
