import { analyticsService } from '@/services/analytics-service';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export const useNavigationTracker = (): void => {
  const location = useLocation();

  useEffect(() => {
    analyticsService.trackPage();
  }, [location.pathname]);
};
