import { eventService } from '@/services/api-clients/event-service';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { EventCreationData } from '../models/event-creation-data';

export const useExistingEvent = (
  onLoadingChange: (loading: boolean) => void,
  patchEventCreationData: (value: Partial<EventCreationData>) => void
) => {
  const navigate = useNavigate();
  const { eventId } = useParams<'eventId'>();

  useEffect(() => {
    (async () => {
      if (!eventId) {
        return;
      }
      onLoadingChange(true);
      try {
        const event = await eventService.getById(eventId);
        if (event) {
          patchEventCreationData({
            eventId: event.id,
            title: event.title,
            game: event.game,
            date: new Date(event.eventAt),
            duration: event.duration,
            platforms: event.platforms,
            gifUrl: event.gifUrl,
            description: event.description,
          });
        } else {
          navigate('/notfound');
        }
      } finally {
        onLoadingChange(false);
      }
    })();
  }, [eventId]);
};
