import ShAvatar from '@/components/ui-framework/ShAvatar/ShAvatar';
import ShIconButton from '@/components/ui-framework/ShIconButton/ShIconButton';
import { useAuthentication } from '@/hooks/use-authentication';
import { User } from '@/services/api-clients/models/user';
import { User as UserIcon } from 'lucide-react';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import './TopBar.scss';

interface Props {
  colorMode: 'light' | 'dark';
}

const TopBar: FC<Props> = ({ colorMode }) => {
  const auth = useAuthentication();
  const navigate = useNavigate();

  const renderProfile = (user: User) => {
    return (
      <ShAvatar
        value={user.avatar}
        size="md"
        onClick={() => navigate('/profile')}
      />
    );
  };

  const renderLogin = () => {
    return (
      <ShIconButton variant={colorMode} onClick={() => navigate('/login')}>
        <UserIcon />
      </ShIconButton>
    );
  };

  return (
    <div
      className={
        colorMode === 'dark'
          ? 'top-bar-root responsive-container'
          : 'top-bar-root responsive-container light'
      }
    >
      <div className="top-bar-title" onClick={() => navigate('/')}>
        <div className="top-bar-logo"></div>
      </div>
      <div className="top-bar-content">
        {auth.user && renderProfile(auth.user)}
        {!auth.user && renderLogin()}
      </div>
    </div>
  );
};

export default TopBar;
