import GamingPlatformsSection from '@/components/Kernel/Common/GamingPlatformsSection/GamingPlatformsSection';
import { DetailedEvent } from '@/services/api-clients/models/detailed-event';
import { FC } from 'react';
import './EventGameSection.scss';

interface Props {
  event: DetailedEvent;
}

const EventGameSection: FC<Props> = props => {
  return (
    <div className="event-game-section-root">
      <div
        className="event-game-section-poster"
        style={{ backgroundImage: `url(${props.event.game.imageUrl})` }}
      ></div>
      <div className="event-game-section-content">
        <div className="event-game-section-title">{props.event.game.title}</div>
        <GamingPlatformsSection size="sm" values={props.event.platforms} />
      </div>
    </div>
  );
};

export default EventGameSection;
