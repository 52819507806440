import { FC, ReactNode, useEffect } from 'react';

export interface PageMetadata {
  title: string;
}

const PageMetadataElement: FC<{
  children: ReactNode;
  metadata: PageMetadata;
}> = ({ children, metadata }) => {
  useEffect(() => {
    document.title = `Sesh - ${metadata.title}`;
  }, [metadata.title]);

  return <>{children}</>;
};

export const withPageMetadata = (Page: FC, metadata: PageMetadata) => {
  const PageWithMetadata: FC = () => (
    <PageMetadataElement metadata={metadata}>
      <Page />
    </PageMetadataElement>
  );

  PageWithMetadata.displayName = `PageWithMetadata(${Page.displayName || Page.name || 'Component'})`;
  return PageWithMetadata;
};
