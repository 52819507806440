import { AxiosRequestConfig } from 'axios';
import { getCurrentUserToken } from '../firebase';
import { ApiClient } from './api-client';

export class BearerTokenApiClient extends ApiClient {
  public constructor(
    private _tokenLoader: () => Promise<string | null> | null
  ) {
    super();
  }

  protected async _getDefaultRequestConfig(): Promise<AxiosRequestConfig> {
    const baseConfig = (await super._getDefaultRequestConfig()) || {};
    const token = await this._tokenLoader();
    const headers = {
      'Content-Type': 'application/json',
    } as any;

    if (token) {
      headers['Authorization'] = `Bearer ${token}`;
    }
    return {
      ...baseConfig,
      headers,
    };
  }
}

export const bearerTokenApiClient = new BearerTokenApiClient(async () => {
  return await getCurrentUserToken();
});
