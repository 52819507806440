import { FC, useEffect, useState } from 'react';
import { ToastService } from './services/toast-service';
import './ShToast.scss';

const ShToast: FC = () => {
  const [message, setMessage] = useState<string | null>(null);

  useEffect(() => {
    return ToastService.subscribe(setMessage);
  }, []);

  return message ? (
    <div className="sh-toast">
      <div className="sh-toast-content">{message}</div>
    </div>
  ) : null;
};

export default ShToast;
