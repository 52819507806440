export enum GamingPlatform {
  PC = 'pc',
  PlayStation3 = 'ps3',
  PlayStation4 = 'ps4',
  PlayStation5 = 'ps5',
  Wii = 'wii',
  WiiU = 'wii-u',
  NintendoSwitch = 'nintendo-switch',
  Xbox360 = 'xbox-360',
  XboxOne = 'xbox-one',
  XboxSeriesXS = 'xbox-series-xs',
}
