import { eventLiveUpdatesService } from '@/services/api-clients/event-live-updates-service';
import { DetailedRsvp } from '@/services/api-clients/models/detailed-rsvp';
import { useEffect } from 'react';

export const useEventRsvpsLiveUpdates = (
  callback: (rsvps: DetailedRsvp[]) => void,
  eventId?: string
) => {
  useEffect(() => {
    if (!eventId) {
      return;
    }
    let unsubscribe: (() => void) | null = null;

    (async () => {
      unsubscribe = await eventLiveUpdatesService.subscribeToRsvps(
        eventId,
        rsvps => callback(rsvps),
        error => console.error(`useEventRsvpsLiveUpdates error: ${error}`)
      );
    })();

    return () => {
      unsubscribe?.();
    };
  }, [eventId]);
};
