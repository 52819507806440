import { Textarea } from '@mantine/core';
import { ChangeEvent, FC, ReactNode } from 'react';
import './ShTextArea.scss';

interface Props {
  label?: string;
  disabled?: boolean;
  value?: string | number;
  placeholder?: string;
  leftSection?: ReactNode;
  rightSection?: ReactNode;
  onChange?: (e: ChangeEvent<HTMLTextAreaElement>) => void;
}

const ShTextArea: FC<Props> = props => {
  return (
    <Textarea
      className="sh-text-area"
      size="lg"
      radius="0.5rem"
      disabled={props.disabled}
      placeholder={props.placeholder}
      value={props.value}
      leftSection={props.leftSection}
      rightSection={props.rightSection}
      onChange={e => props.onChange?.(e)}
      label={props.label}
    />
  );
};

export default ShTextArea;
