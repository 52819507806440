import ShButton from '@/components/ui-framework/ShButton/ShButton';
import ShLabel from '@/components/ui-framework/ShLabel/ShLabel';
import { useAuthentication } from '@/hooks/use-authentication';
import { eventService } from '@/services/api-clients/event-service';
import { DetailedEvent } from '@/services/api-clients/models/detailed-event';
import { LoadingOverlay } from '@mantine/core';
import { PlusCircleIcon } from 'lucide-react';
import { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from '../../Common/Header/Header';
import { withPageMetadata } from '../../Common/PageMetadata/PageMetadata';
import StickySection from '../../Common/StickySection/StickySection';
import EventCardGroup from './components/EventCardGroup/EventCardGroup';
import './HomePage.scss';

const HomePage: FC = () => {
  const navigate = useNavigate();
  const auth = useAuthentication();

  const [loading, setLoading] = useState(true);
  const [allEvents, setAllEvents] = useState<DetailedEvent[]>([]);
  const [myEvents, setMyEvents] = useState<DetailedEvent[]>([]);
  const [rsvpEvents, setRsvpEvents] = useState<DetailedEvent[]>([]);
  const [selectedTab, setSelectedTab] = useState<
    'hosting' | 'upcoming' | 'all'
  >('all');

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const page = await eventService.getAll();
        setAllEvents(page.items);
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  useEffect(() => {
    const myEvents = allEvents.filter(e => e.createdBy.id === auth.user?.id);
    const rsvpEvents = allEvents.filter(e =>
      e.rsvps.some(r => r.id === auth.user?.id)
    );
    setMyEvents(myEvents);
    setRsvpEvents(rsvpEvents);
  }, [allEvents]);

  const getPosterUrl = (event: DetailedEvent) => {
    return event.media?.homePage || event.game.imageUrl;
  };

  const renderHeroSection = () => {
    const title = auth.user
      ? `Welcome back, @${auth.user.username}`
      : 'Welcome to Sesh!';
    const upcomingEventsCount = rsvpEvents.length + myEvents.length;
    const subtitle =
      auth.user && upcomingEventsCount > 0
        ? `You have ${upcomingEventsCount} upcoming events`
        : '';
    return (
      <div className="home-page-hero">
        <Header title={title} subtitle={subtitle} />
        <div className="home-page-hero-actions">
          <ShButton onClick={() => navigate('/events/create')}>
            <PlusCircleIcon /> Create Sesh
          </ShButton>
        </div>
      </div>
    );
  };

  const renderTabs = () => {
    return (
      <div className="home-page-tabs">
        <div
          className={
            'home-page-tabs-entry ' +
            (selectedTab === 'hosting' ? 'selected' : '')
          }
          onClick={() => setSelectedTab('hosting')}
        >
          Hosted
        </div>
        <div
          className={
            'home-page-tabs-entry ' +
            (selectedTab === 'upcoming' ? 'selected' : '')
          }
          onClick={() => setSelectedTab('upcoming')}
        >
          Upcoming
        </div>
        <div
          className={
            'home-page-tabs-entry ' + (selectedTab === 'all' ? 'selected' : '')
          }
          onClick={() => setSelectedTab('all')}
        >
          All
        </div>
      </div>
    );
  };

  const renderStickers = () => {
    return (
      <div className="home-page-stickers">
        <div className="home-page-sticker1" />
        <div className="home-page-sticker2" />
        <div className="home-page-sticker3" />
        <div className="home-page-sticker4" />
        <div className="home-page-sticker5" />
      </div>
    );
  };

  return (
    <>
      <div className="home-page-root responsive-container">
        {renderStickers()}
        {renderHeroSection()}
        {auth.user && renderTabs()}
        {selectedTab === 'hosting' && auth.user && (
          <EventCardGroup events={myEvents} getPosterUrl={getPosterUrl} />
        )}
        {selectedTab === 'upcoming' && auth.user && (
          <EventCardGroup events={rsvpEvents} getPosterUrl={getPosterUrl} />
        )}
        {selectedTab === 'all' && (
          <EventCardGroup events={allEvents} getPosterUrl={getPosterUrl} />
        )}
      </div>
      <LoadingOverlay visible={loading} />
      <StickySection shadow={false}>
        <ShLabel secondary={true} centered={true}>
          © 2024 Forge, Inc
        </ShLabel>
      </StickySection>
    </>
  );
};

export default withPageMetadata(HomePage, {
  title: 'Home',
});
