import { Button } from '@mantine/core';
import { FC, PropsWithChildren } from 'react';
import './ShButton.scss';

interface Props {
  disabled?: boolean;
  variant?: 'light' | 'dark' | 'accent';
  justify?: 'start' | 'center' | 'end';
  upperCase?: boolean;
  onClick?: () => void;
}

const ShButton: FC<PropsWithChildren<Props>> = props => {
  const light = () => {
    return (
      <Button
        size="lg"
        variant="default"
        className={`sh-button light ${props.upperCase ? 'uppercase' : ''}`}
        disabled={props.disabled}
        justify={props.justify}
        onClick={() => props.onClick?.()}
      >
        {props.children}
      </Button>
    );
  };

  const dark = () => {
    return (
      <Button
        size="lg"
        variant="filled"
        className={`sh-button dark ${props.upperCase ? 'uppercase' : ''}`}
        color="#0f172a"
        disabled={props.disabled}
        justify={props.justify}
        onClick={() => props.onClick?.()}
      >
        {props.children}
      </Button>
    );
  };

  const accent = () => {
    return (
      <Button
        size="lg"
        variant="gradient"
        gradient={{ from: '#f98316', to: '#f0c93f', deg: 270 }}
        className={`sh-button accent ${props.upperCase ? 'uppercase' : ''}`}
        disabled={props.disabled}
        justify={props.justify}
        onClick={() => props.onClick?.()}
      >
        {props.children}
      </Button>
    );
  };

  const render = () => {
    switch (props.variant) {
      case 'dark':
        return dark();
      case 'accent':
        return accent();
      default:
        return light();
    }
  };

  return render();
};

export default ShButton;
