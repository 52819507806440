import { ActionIcon } from '@mantine/core';
import { FC, MouseEvent, PropsWithChildren } from 'react';
import ShTooltip from '../ShTooltip/ShTooltip';

interface Props {
  disabled?: boolean;
  variant?: 'light' | 'dark' | 'solid' | 'accent';
  size?: 'md' | 'lg';
  title?: string;
  onClick?: (e: MouseEvent<HTMLButtonElement>) => void;
}

const ShIconButton: FC<PropsWithChildren<Props>> = props => {
  const getSize = () => {
    const size = props.size || 'md';
    return size === 'md' ? 'xl' : '4rem';
  };

  const light = () => {
    return (
      <ActionIcon
        variant="subtle"
        radius="100%"
        size={getSize()}
        color="black"
        onClick={e => props.onClick?.(e)}
      >
        {props.children}
      </ActionIcon>
    );
  };

  const dark = () => {
    return (
      <ActionIcon
        variant="subtle"
        radius="100%"
        size={getSize()}
        color="white"
        onClick={e => props.onClick?.(e)}
      >
        {props.children}
      </ActionIcon>
    );
  };

  const solid = () => {
    return (
      <ActionIcon
        variant="default"
        radius="100%"
        size={getSize()}
        color="black"
        onClick={e => props.onClick?.(e)}
      >
        {props.children}
      </ActionIcon>
    );
  };

  const accent = () => {
    return (
      <ActionIcon
        variant="gradient"
        gradient={{ from: '#f98316', to: '#f0c93f', deg: 270 }}
        radius="100%"
        size={getSize()}
        onClick={e => props.onClick?.(e)}
      >
        {props.children}
      </ActionIcon>
    );
  };

  const render = () => {
    switch (props.variant) {
      case 'dark':
        return dark();
      case 'solid':
        return solid();
      case 'accent':
        return accent();
      default:
        return light();
    }
  };

  return props.title ? (
    <ShTooltip value={props.title}>{render()}</ShTooltip>
  ) : (
    render()
  );
};

export default ShIconButton;
