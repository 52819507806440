import ShButton from '@/components/ui-framework/ShButton/ShButton';
import ShLabel from '@/components/ui-framework/ShLabel/ShLabel';
import { useAuthentication } from '@/hooks/use-authentication';
import { User } from '@/services/api-clients/models/user';
import { userService } from '@/services/api-clients/user-service';
import { SessionManager } from '@/services/session-manager';
import { LoadingOverlay } from '@mantine/core';
import { FC, useState } from 'react';
import { withAuthProtection } from '../../Common/AuthProtectedPage/AuthProtectedPage';
import { withPageMetadata } from '../../Common/PageMetadata/PageMetadata';
import AvatarSelector from '../RegisterPage/components/AvatarSelector/AvatarSelector';
import './ProfilePage.scss';

const ProfilePage: FC = () => {
  const auth = useAuthentication();
  const [loading, setLoading] = useState(false);

  const onAvatarSelected = async (avatar: string) => {
    try {
      setLoading(true);
      const user = await userService.updateAvatar(avatar);
      SessionManager.setUser(user);
    } finally {
      setLoading(false);
    }
  };

  const render = (user: User) => {
    return (
      <div className="profile-page-root">
        <AvatarSelector
          value={user.avatar}
          onSelected={x => onAvatarSelected(x)}
        />
        <ShLabel secondary={true}>@{user.username}</ShLabel>
        <ShButton onClick={() => auth.logout()}>Logout</ShButton>
        <LoadingOverlay visible={loading} />
      </div>
    );
  };

  return auth.user ? render(auth.user) : <LoadingOverlay visible={true} />;
};

export default withPageMetadata(withAuthProtection(ProfilePage), {
  title: 'Profile',
});
