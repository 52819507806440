import { bearerTokenWsClient } from './bearer-token-ws-client';
import { DetailedEventComment } from './models/detailed-event-comment';
import { DetailedRsvp } from './models/detailed-rsvp';
import { WsClient } from './ws-client';

export class EventLiveUpdateService {
  public constructor(private _wsClient: WsClient) {}

  public async subscribeToRsvps(
    eventId: string,
    onData: (rsvps: DetailedRsvp[]) => void,
    onError: (error: Error) => void
  ): Promise<() => void> {
    return this._wsClient.subscribe(
      'event-rsvps',
      { eventId },
      data => onData(data),
      error => onError(error)
    );
  }

  public async subscribeToComments(
    eventId: string,
    onData: (comments: DetailedEventComment[]) => void,
    onError: (error: Error) => void
  ): Promise<() => void> {
    return this._wsClient.subscribe(
      'event-comments',
      { eventId },
      data => onData(data),
      error => onError(error)
    );
  }
}

export const eventLiveUpdatesService = new EventLiveUpdateService(
  bearerTokenWsClient
);
