import { px } from '@mantine/core';
import { FC, PropsWithChildren, useEffect, useRef, useState } from 'react';
import './StickySection.scss';

interface Props {
  variant?: 'light' | 'dark' | 'transparent';
  direction?: 'column' | 'row';
  anchor?: 'bottom' | 'top';
  shadow?: boolean;
}

const StickySection: FC<PropsWithChildren<Props>> = props => {
  const sectionRef = useRef<HTMLDivElement>(null);
  const [isScrolling, setScrolling] = useState(true);
  const [spacerHeight, setSpacerHeight] = useState(0);
  const [stickTo] = useState(props.anchor || 'bottom');

  const checkScroll = () => {
    const scrollTop =
      (document.documentElement && document.documentElement.scrollTop) ||
      document.body.scrollTop;
    const scrollHeight =
      (document.documentElement && document.documentElement.scrollHeight) ||
      document.body.scrollHeight;
    const clientHeight =
      document.documentElement.clientHeight || window.innerHeight;

    if (stickTo === 'top') {
      const toTop = scrollTop === 0;
      setScrolling(!toTop);
    } else {
      const toBottom = Math.ceil(scrollTop + clientHeight) >= scrollHeight;
      setScrolling(!toBottom);
    }
  };

  useEffect(() => {
    setSpacerHeight(sectionRef.current?.clientHeight ?? 0);
  }, [sectionRef]);

  useEffect(() => {
    const handler = () => checkScroll();

    setTimeout(() => checkScroll(), 100);
    window.addEventListener('scroll', handler);
    window.addEventListener('resize', handler);

    return () => {
      window.removeEventListener('scroll', handler);
      window.removeEventListener('resize', handler);
    };
  }, []);

  const getClassNames = () => {
    const classNames = ['sticky-section'];
    if (isScrolling && props.variant === 'transparent') {
      classNames.push('acrylic');
    }
    classNames.push(stickTo === 'top' ? 'to-top' : 'to-bottom');
    if (isScrolling && props.shadow !== false) {
      classNames.push('with-shadow');
    }
    return classNames.join(' ');
  };

  const getBackgroundColor = () => {
    if (!isScrolling && props.variant === 'transparent') {
      return 'transparent';
    }
    if (props.variant === 'transparent') {
      return 'rgba(0,0,0, 0.2)';
    }
    return props.variant === 'dark' ? 'black' : '#f9fbfd';
  };

  return (
    <div className="sticky-section-root">
      <div
        className="sticky-section-spacer"
        style={{
          height: px(spacerHeight),
        }}
      />
      <div
        className={getClassNames()}
        ref={sectionRef}
        style={{
          backgroundColor: getBackgroundColor(),
          flexDirection: props.direction || 'column',
        }}
      >
        {props.children}
      </div>
    </div>
  );
};

export default StickySection;
