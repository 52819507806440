import ShLabel from '@/components/ui-framework/ShLabel/ShLabel';
import { DetailedEvent } from '@/services/api-clients/models/detailed-event';
import { FC } from 'react';
import EventCard from '../EventCard/EventCard';
import './EventCardGroup.scss';

interface Props {
  events: DetailedEvent[];
  getPosterUrl: (event: DetailedEvent) => string;
}

const EventCardGroup: FC<Props> = ({ events, getPosterUrl }) => {
  return (
    <div className="event-card-group-root">
      {events.length > 0 && (
        <div className="event-card-group-content">
          {events.map(event => (
            <EventCard
              key={event.id}
              getPosterUrl={getPosterUrl}
              event={event}
            />
          ))}
        </div>
      )}

      {events.length === 0 && (
        <div className="event-card-placeholder">
          <ShLabel secondary={true} centered={true}>
            There are no sesh available here yet.
          </ShLabel>
        </div>
      )}
    </div>
  );
};

export default EventCardGroup;
