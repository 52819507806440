import ShButton from '@/components/ui-framework/ShButton/ShButton';
import ShLabel from '@/components/ui-framework/ShLabel/ShLabel';
import { FC } from 'react';
import './DurationSelector.scss';

interface Props {
  value: number;
  onSelected: (duration: number) => void;
  onCancel: () => void;
}

const DurationSelector: FC<Props> = ({ onSelected, onCancel, value }) => {
  return (
    <div className="duration-selector-root">
      <ShLabel>Select a duration</ShLabel>
      {Array.from({ length: 6 }).map((_, i) => (
        <ShButton
          variant={value === 60 * (i + 1) ? 'dark' : 'light'}
          key={i}
          onClick={() => onSelected(60 * (i + 1))}
        >
          {i + 1} {i === 0 ? 'hour' : 'hours'}
        </ShButton>
      ))}

      <ShButton onClick={() => onCancel()}>Cancel</ShButton>
    </div>
  );
};

export default DurationSelector;
