import { useNavigationTracker } from '@/hooks/use-navigation-tracker';
import { Route, Routes } from 'react-router-dom';
import ShToast from '../ui-framework/ShToast/ShToast';
import Analytics from './Common/Analytics/Analytics';
import GlobalErrorHandler from './Common/GlobalErrorHandler/GlobalErrorHandler';
import { MainLayout } from './Layouts/MainLayout/MainLayout';
import EventDetailsPage from './Pages/EventDetailsPage/EventDetailsPage';
import EventEditorPage from './Pages/EventEditorPage/EventEditorPage';
import EventLoungePage from './Pages/EventLoungePage/EventLoungePage';
import HomePage from './Pages/HomePage/HomePage';
import LoginPage from './Pages/LoginPage/LoginPage';
import NotFoundPage from './Pages/NotFoundPage/NotFoundPage';
import ProfilePage from './Pages/ProfilePage/ProfilePage';
import RegisterPage from './Pages/RegisterPage/RegisterPage';

export const Kernel = () => {
  useNavigationTracker();
  return (
    <GlobalErrorHandler>
      <Routes>
        <Route path="/" element={<MainLayout />}>
          <Route index element={<HomePage />} />
          <Route path="login" element={<LoginPage />} />
          <Route path="register" element={<RegisterPage />} />
          <Route path="events/create" element={<EventEditorPage />} />
          <Route path="events/:eventId/edit" element={<EventEditorPage />} />
          <Route path="events/:eventId/lounge" element={<EventLoungePage />} />
          <Route path="events/:eventId" element={<EventDetailsPage />} />
          <Route path="profile" element={<ProfilePage />} />
          <Route path="*" element={<NotFoundPage />} />
        </Route>
      </Routes>
      <Analytics />
      <ShToast />
    </GlobalErrorHandler>
  );
};
