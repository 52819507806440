import ShIconButton from '@/components/ui-framework/ShIconButton/ShIconButton';
import {
  MessagesSquareIcon,
  MicOffIcon,
  UsersIcon,
  VolumeXIcon,
} from 'lucide-react';
import { FC } from 'react';
import './EventLoungeActionsSection.scss';

const EventLoungeActionsSection: FC = () => {
  return (
    <div className="event-lounge-actions-section-root">
      <div className="event-lounge-actions-section-item">
        <ShIconButton variant="solid" size="lg">
          <MicOffIcon />
        </ShIconButton>
      </div>
      <div className="event-lounge-actions-section-item">
        <ShIconButton variant="solid" size="lg">
          <UsersIcon />
        </ShIconButton>
      </div>
      <div className="event-lounge-actions-section-item">
        <ShIconButton variant="solid" size="lg">
          <MessagesSquareIcon />
        </ShIconButton>
      </div>
      <div className="event-lounge-actions-section-item">
        <ShIconButton variant="solid" size="lg">
          <VolumeXIcon />
        </ShIconButton>
      </div>
    </div>
  );
};

export default EventLoungeActionsSection;
