import ShIconButton from '@/components/ui-framework/ShIconButton/ShIconButton';
import { Gamepad2Icon, ImageOffIcon, ImagePlusIcon } from 'lucide-react';
import { FC } from 'react';
import Header from '../Header/Header';
import './EventPoster.scss';

interface Props {
  title?: string;
  gifUrl?: string;
  imageUrl?: string;
  editorMode?: {
    onAddGif?: () => void;
    onRemoveGif?: () => void;
    onChangeGame?: () => void;
  };
  variant?: 'dark' | 'light';
}

const EventPoster: FC<Props> = props => {
  return (
    <div className="event-poster-root">
      {props.title && (
        <Header variant={props.variant} title={props.title} centered={true} />
      )}
      <div
        className="event-poster-image"
        style={{
          backgroundImage: 'url(' + (props.gifUrl || props.imageUrl) + ')',
        }}
      />
      {props.editorMode && (
        <div className="event-poster-actions">
          {!props.gifUrl && (
            <ShIconButton
              title="Choose a GIF"
              variant="dark"
              onClick={() => props.editorMode?.onAddGif?.()}
            >
              <ImagePlusIcon />
            </ShIconButton>
          )}

          {props.gifUrl && (
            <ShIconButton
              title="Remove GIF"
              variant="dark"
              onClick={() => props.editorMode?.onRemoveGif?.()}
            >
              <ImageOffIcon />
            </ShIconButton>
          )}

          <ShIconButton
            title="Change game"
            variant="dark"
            onClick={() => props.editorMode?.onChangeGame?.()}
          >
            <Gamepad2Icon />
          </ShIconButton>
        </div>
      )}
    </div>
  );
};

export default EventPoster;
