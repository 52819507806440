import { TextInput } from '@mantine/core';
import {
  ChangeEvent,
  FC,
  FocusEvent,
  HTMLInputTypeAttribute,
  ReactNode,
} from 'react';
import './ShInput.scss';

interface Props {
  label?: string;
  disabled?: boolean;
  value?: string | number;
  type?: HTMLInputTypeAttribute;
  placeholder?: string;
  leftSection?: ReactNode;
  rightSection?: ReactNode;
  maxLength?: number;
  variant?: 'light' | 'transparent';
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  onFocus?: (e: FocusEvent<HTMLInputElement>) => void;
  onBlur?: (e: FocusEvent<HTMLInputElement>) => void;
}

const ShInput: FC<Props> = props => {
  return (
    <TextInput
      className={'sh-input ' + (props.variant || 'light')}
      size="lg"
      radius="0.5rem"
      disabled={props.disabled}
      placeholder={props.placeholder}
      value={props.value}
      type={props.type}
      leftSection={props.leftSection}
      rightSection={props.rightSection}
      onChange={e => props.onChange?.(e)}
      onFocus={e => props.onFocus?.(e)}
      onBlur={e => props.onBlur?.(e)}
      label={props.label}
      maxLength={props.maxLength}
    />
  );
};

export default ShInput;
