import ShIconButton from '@/components/ui-framework/ShIconButton/ShIconButton';
import ShInput from '@/components/ui-framework/ShInput/ShInput';
import { useAuthentication } from '@/hooks/use-authentication';
import { useEventCommentsLiveUpdates } from '@/hooks/use-event-comments-live-updates';
import { eventService } from '@/services/api-clients/event-service';
import { DetailedEventComment } from '@/services/api-clients/models/detailed-event-comment';
import { getRouteWithRedirect } from '@/services/route-redirection';
import { MessagesSquareIcon, SendHorizonalIcon } from 'lucide-react';
import { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import EventCommentsCard from './components/EventCommentCard/EventCommentCard';
import './EventCommentsSection.scss';

interface Props {
  eventId?: string;
  isClosed?: boolean;
}

const EventCommentsSection: FC<Props> = props => {
  const navigate = useNavigate();
  const [message, setMessage] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [comments, setComments] = useState<DetailedEventComment[]>([]);

  const auth = useAuthentication();
  useEventCommentsLiveUpdates(x => setComments(x), props.eventId);

  const sendMessage = async () => {
    if (!message || !props.eventId) {
      return;
    }
    try {
      setLoading(true);
      await eventService.comment(props.eventId, message);
      setMessage('');
    } finally {
      setLoading(false);
    }
  };

  const renderControls = () => {
    if (props.isClosed) {
      return null;
    }

    const onClick = () => {
      if (!auth.user) {
        const url = getRouteWithRedirect(`/login`);
        navigate(url);
      }
    };

    return (
      <div
        className="event-comments-section-controls"
        onClick={() => onClick()}
      >
        <ShInput
          variant="transparent"
          placeholder="Leave a comment"
          disabled={loading}
          value={message}
          maxLength={1000}
          onChange={e => setMessage(e.target.value)}
          rightSection={
            <ShIconButton
              variant="dark"
              disabled={loading}
              onClick={() => sendMessage()}
            >
              <SendHorizonalIcon />
            </ShIconButton>
          }
        />
      </div>
    );
  };

  return (
    <div className="event-comments-section-root">
      <div className="event-comments-section-header">
        <MessagesSquareIcon />
        Comments
      </div>
      {renderControls()}
      <div className="event-comments-section-content">
        {comments.map(comment => (
          <EventCommentsCard key={comment.id} comment={comment} />
        ))}
      </div>
    </div>
  );
};

export default EventCommentsSection;
