import { eventLiveUpdatesService } from '@/services/api-clients/event-live-updates-service';
import { DetailedEventComment } from '@/services/api-clients/models/detailed-event-comment';
import { useEffect } from 'react';

export const useEventCommentsLiveUpdates = (
  callback: (comments: DetailedEventComment[]) => void,
  eventId?: string
) => {
  useEffect(() => {
    if (!eventId) {
      return;
    }
    let unsubscribe: (() => void) | null = null;

    (async () => {
      unsubscribe = await eventLiveUpdatesService.subscribeToComments(
        eventId,
        comments => callback(comments),
        error => console.error(`useEventCommentsLiveUpdates error: ${error}`)
      );
    })();

    return () => {
      unsubscribe?.();
    };
  }, [eventId]);
};
