import dayjs from 'dayjs';
import { ApiClient } from './api-client';
import { BaseService } from './base-service';
import { bearerTokenApiClient } from './bearer-token-api-client';
import { DetailedEvent } from './models/detailed-event';
import { EventCreationRequest } from './models/event-creation-request';
import { EventUpdateRequest } from './models/event-update-request';
import { Page } from './models/page';
import { RsvpStatus } from './models/rsvp-status';

export class EventService extends BaseService {
  public constructor(apiClient: ApiClient) {
    super(apiClient, 'events');
  }

  public async getAll(
    page?: number,
    size?: number
  ): Promise<Page<DetailedEvent>> {
    const queryParams = [];
    if (page) {
      queryParams.push(`page=${page}`);
    }
    if (size) {
      queryParams.push(`size=${size}`);
    }
    const query = queryParams.join('&');
    const url = this.url(`/?${query}`);

    return this.apiClient.get(url);
  }

  public async getById(id: string): Promise<DetailedEvent | null> {
    const url = this.url(`/${id}`);
    return this.apiClient.get(url);
  }

  public async create(
    eventCreationRequest: EventCreationRequest
  ): Promise<DetailedEvent> {
    return this.apiClient.post(this.url(), {
      ...eventCreationRequest,
      eventAt: dayjs(eventCreationRequest.eventAt).format(),
    } as any);
  }

  public async update(eventUpdateRequest: EventUpdateRequest): Promise<void> {
    const { id, ...data } = eventUpdateRequest;
    const url = this.url(`/${id}`);
    return this.apiClient.put(url, {
      ...data,
      eventAt: dayjs(data.eventAt).format(),
    } as any);
  }

  public async getRsvp(eventId: string): Promise<RsvpStatus | null> {
    const url = this.url(`/${eventId}/rsvp`);
    const data = await this.apiClient.get<{ status: RsvpStatus | null }>(url);
    return data.status;
  }

  public async setRsvp(eventId: string, status: RsvpStatus): Promise<void> {
    const url = this.url(`/${eventId}/rsvp`);
    return this.apiClient.post(url, { status });
  }

  public async downloadIcs(eventId: string): Promise<void> {
    const url = this.url(`/${eventId}/ics`);
    const data = await this.apiClient.get<any>(url, {
      headers: {
        responseType: 'blob',
      },
    });
    const blob = new Blob([data], { type: 'text/calendar' });
    const downloadUrl = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = downloadUrl;
    link.setAttribute('download', `sesh_event_${eventId}.ics`);
    document.body.appendChild(link);
    link.click();
    link.remove();
    window.URL.revokeObjectURL(downloadUrl);
  }

  public async comment(id: string, message: string): Promise<void> {
    const url = this.url(`/${id}/comments`);
    return this.apiClient.post(url, {
      message,
    });
  }
}

export const eventService = new EventService(bearerTokenApiClient);
