import { EventTimeWindow } from '@/hooks/use-event-time-window';
import { FC } from 'react';
import './EventTimeWindowIndicator.scss';

interface Props {
  timeWindow: EventTimeWindow;
}

const EventTimeWindowIndicator: FC<Props> = props => {
  const getClassNames = () => {
    if (props.timeWindow.isAboutToStart) {
      return 'about-to-start';
    }
    if (props.timeWindow.inProgress) {
      return 'in-progress';
    }
    if (props.timeWindow.isClosed) {
      return 'closed';
    }
  };

  const getText = () => {
    if (props.timeWindow.isAboutToStart) {
      return `Starting in ${props.timeWindow.minutesToStart} minute${props.timeWindow.minutesToStart === 1 ? '' : 's'}`;
    }
    if (props.timeWindow.inProgress) {
      return 'Event in progress';
    }
    if (props.timeWindow.isClosed) {
      return 'This event has ended';
    }
  };

  if (!props.timeWindow.hasStarted && !props.timeWindow.isAboutToStart) {
    return null;
  }

  return (
    <div className={'event-time-window-indicator-root ' + getClassNames()}>
      {getText()}
    </div>
  );
};

export default EventTimeWindowIndicator;
