import StickySection from '@/components/Kernel/Common/StickySection/StickySection';
import ShButton from '@/components/ui-framework/ShButton/ShButton';
import ShInput from '@/components/ui-framework/ShInput/ShInput';
import ShLabel from '@/components/ui-framework/ShLabel/ShLabel';
import { useThrottledEffect } from '@/hooks/use-throttled-effect';
import { configuration } from '@/services/configuration';
import axios from 'axios';
import { HourglassIcon, SearchIcon } from 'lucide-react';
import { FC, useState } from 'react';
import './GiphySelector.scss';

interface Props {
  initialSearch?: string;
  onSelected: (value: string) => void;
  onCancel: () => void;
}

const GiphySelector: FC<Props> = ({ initialSearch, onSelected, onCancel }) => {
  const [search, setSearch] = useState(initialSearch || '');
  const [loading, setLoading] = useState(false);
  const [gifs, setGifs] = useState<any[]>([]);

  useThrottledEffect(
    () => {
      (async () => {
        try {
          setLoading(true);
          const response = await axios.get(
            'https://api.giphy.com/v1/gifs/search',
            {
              params: {
                q: search || 'gaming',
                api_key: configuration.giphy.apiKey,
                limit: 10,
              },
            }
          );
          setGifs(response.data.data);
        } finally {
          setLoading(false);
        }
      })();
    },
    500,
    [search]
  );

  const getIcon = () =>
    loading ? (
      <HourglassIcon className="icon" />
    ) : (
      <SearchIcon className="icon" />
    );

  return (
    <div className="giphy-selector-root">
      <ShLabel>Select a GIF</ShLabel>
      <ShInput
        leftSection={getIcon()}
        value={search}
        onChange={e => setSearch(e.target.value)}
      />
      {gifs.map((gif: any) => (
        <img
          key={gif.id}
          src={gif.images.fixed_height.url}
          onClick={() => onSelected(gif.images.fixed_height.url)}
        />
      ))}
      <StickySection>
        <ShButton onClick={() => onCancel()}>Cancel</ShButton>
      </StickySection>
    </div>
  );
};

export default GiphySelector;
