import ShButton from '@/components/ui-framework/ShButton/ShButton';
import { auth } from '@/services/firebase';
import { SessionManager } from '@/services/session-manager';
import { FC, ReactNode, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './GlobalErrorHandler.scss';
import { mapError } from './services/error-mapper';

const GlobalErrorHandler: FC<{ children: ReactNode }> = ({ children }) => {
  const [error, setError] = useState<Error | null>(null);
  const [message, setMessage] = useState<string | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    const originalOnError = window.onerror;

    const unhandledRejectionHandler = (event: PromiseRejectionEvent) => {
      setError(event.reason);
      console.error(
        '[GlobalErrorHandler] [unhandledRejectionHandler]:',
        event.reason
      );
    };

    const onError: OnErrorEventHandler = (
      event: Event | string,
      source?: string,
      lineno?: number,
      colno?: number,
      error?: Error
    ) => {
      if (error) {
        setError(error);
      }

      console.error('[GlobalErrorHandler] [onError]', {
        event,
        source,
        lineno,
        colno,
        error,
      });
      return false;
    };

    window.addEventListener('unhandledrejection', unhandledRejectionHandler);
    window.onerror = onError;

    return () => {
      window.removeEventListener(
        'unhandledrejection',
        unhandledRejectionHandler
      );
      window.onerror = originalOnError;
    };
  }, []);

  useEffect(() => {
    if (error) {
      const mappedError = mapError(error);
      if (!mappedError.silent && mappedError.message) {
        setMessage(mappedError.message);
      }
      if (mappedError.setAuthStep) {
        SessionManager.setAuthStep(mappedError.setAuthStep);
      }
      if (mappedError.logout) {
        auth.signOut();
        SessionManager.clear();
      }
      if (mappedError.redirect) {
        navigate(mappedError.redirect);
      }
    } else {
      setMessage(null);
    }
  }, [error, setMessage]);

  const resetError = () => {
    setError(null);
  };

  const renderError = () => {
    return (
      <div className="global-error-handler-overlay">
        <div className="global-error-handler-content">
          <div className="global-error-handler-title">Error</div>
          <div className="global-error-handler-message">{message}</div>
          <div className="global-error-handler-actions">
            <ShButton onClick={() => resetError()}>Close</ShButton>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      {message && renderError()}
      {children}
    </>
  );
};

export default GlobalErrorHandler;
