import { ApiClient } from './api-client';
import { BaseService } from './base-service';
import { bearerTokenApiClient } from './bearer-token-api-client';
import { Game } from './models/game';
import { Page } from './models/page';

export class GameService extends BaseService {
  public constructor(apiClient: ApiClient) {
    super(apiClient, 'games');
  }

  public async getAll(page?: number, size?: number): Promise<Page<Game>> {
    const queryParams = [];
    if (page) {
      queryParams.push(`page=${page}`);
    }
    if (size) {
      queryParams.push(`size=${size}`);
    }
    const query = queryParams.join('&');
    const url = this.url(`/?${query}`);

    return this.apiClient.get(url);
  }

  public async search(query: string): Promise<Game[]> {
    const url = this.url(`/search?q=${query}`);
    return this.apiClient.get(url);
  }

  public async getById(id: string): Promise<Game | null> {
    const url = this.url(`/${id}`);
    return this.apiClient.get(url);
  }

  public async create(game: Omit<Game, 'id'>): Promise<Game> {
    return this.apiClient.post(this.url(), game);
  }
}

export const gameService = new GameService(bearerTokenApiClient);
