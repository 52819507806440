import { FC, PropsWithChildren } from 'react';
import './ShLabel.scss';

interface Props {
  variant?: 'light' | 'dark';
  size?: 'sm' | 'md' | 'lg';
  secondary?: boolean;
  centered?: boolean;
}

const ShLabel: FC<PropsWithChildren<Props>> = props => {
  const getClassNames = () => {
    const classNames = [
      'sh-label',
      props.variant || 'dark',
      props.size || 'md',
    ];

    if (props.secondary) {
      classNames.push('secondary');
    }

    if (props.centered) {
      classNames.push('centered');
    }

    return classNames.join(' ');
  };

  return <div className={getClassNames()}>{props.children}</div>;
};

export default ShLabel;
