import { useAuthentication } from '@/hooks/use-authentication';
import { analyticsService } from '@/services/analytics-service';
import { configuration } from '@/services/configuration';
import { FC, useEffect } from 'react';

const Analytics: FC = () => {
  const auth = useAuthentication();

  useEffect(() => {
    const wnd = window as any;
    if (wnd.analytics) {
      return;
    }
    wnd.analytics = {};
    const juneify = (writeKey: string) => {
      wnd.analytics._writeKey = writeKey;
      const script = document.createElement('script');
      script.type = 'application/javascript';
      script.onload = function () {
        wnd.analytics.page();
      };
      script.src =
        'https://unpkg.com/@june-so/analytics-next/dist/umd/standalone.js';
      const first = document.getElementsByTagName('script')[0];
      if (first.parentNode) {
        first.parentNode.insertBefore(script, first);
      }
    };
    juneify(configuration.juneAnalytics.writeKey);
  }, []);

  useEffect(() => {
    if (auth.user) {
      analyticsService.identify(auth.user);
    } else {
      analyticsService.reset();
    }
  }, [auth.user]);

  return null;
};

export default Analytics;
