import { getAuth } from 'firebase/auth';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';

const firebaseConfig = {
  apiKey: 'AIzaSyA4UJ1SIHWQxwxAGoILBmUT8cdSgVb8QC8',
  authDomain: 'forge-meetup.firebaseapp.com',
  projectId: 'forge-meetup',
  storageBucket: 'forge-meetup.appspot.com',
  messagingSenderId: '338408497931',
  appId: '1:338408497931:web:7449e9653b86008e98780e',
  measurementId: 'G-62HH1LCQ48',
};

const firebaseApp = firebase.initializeApp(firebaseConfig);
const auth = getAuth(firebaseApp);

const getCurrentUserToken = (): Promise<string | null> => {
  return new Promise((resolve, reject) => {
    const unsubscribe = auth.onAuthStateChanged(user => {
      unsubscribe();
      if (user) {
        user.getIdToken().then(token => {
          resolve(token);
        }, reject);
      } else {
        resolve(null);
      }
    }, reject);
  });
};

export { auth, getCurrentUserToken };
