import {
  getGamingPlatformIcon,
  getGamingPlatformLabel,
} from '@/components/Kernel/Common/GamingPlatformIcons/GamingPlatformIcons';
import StickySection from '@/components/Kernel/Common/StickySection/StickySection';
import ShButton from '@/components/ui-framework/ShButton/ShButton';
import ShLabel from '@/components/ui-framework/ShLabel/ShLabel';
import { GamingPlatform } from '@/services/api-clients/models/gaming-platform';
import { FC, useEffect, useState } from 'react';
import './GamingPlatformSelector.scss';

interface Props {
  value: GamingPlatform[];
  onSelected?: (platforms: GamingPlatform[]) => void;
  onCancel?: () => void;
}

type GamingPlatformSelection = { [plaform in GamingPlatform]: true };

const AvailablePlatforms = [
  GamingPlatform.PC,
  GamingPlatform.PlayStation3,
  GamingPlatform.PlayStation4,
  GamingPlatform.PlayStation5,
  GamingPlatform.Xbox360,
  GamingPlatform.XboxOne,
  GamingPlatform.XboxSeriesXS,
  GamingPlatform.Wii,
  GamingPlatform.WiiU,
  GamingPlatform.NintendoSwitch,
];

const GamingPlatformSelector: FC<Props> = props => {
  const [selection, setSelection] = useState<GamingPlatformSelection>(
    {} as GamingPlatformSelection
  );

  useEffect(() => {
    const newSelection = props.value.reduce(
      (acc, x) => {
        acc[x] = true;
        return acc;
      },
      {} as { [plaform in GamingPlatform]: true }
    );

    setSelection(newSelection);
  }, [props.value]);

  const onSelected = (platform: GamingPlatform) => {
    if (selection[platform]) {
      delete selection[platform];
    } else {
      selection[platform] = true;
    }

    setSelection({ ...selection });
  };

  const getAvailablePlatforms = () => {
    return AvailablePlatforms.map((x, i) => (
      <div
        className={
          selection[x]
            ? 'gaming-platfrom-selector-item selected'
            : 'gaming-platfrom-selector-item'
        }
        key={i}
        onClick={() => onSelected(x)}
      >
        <div className="gaming-platform-selector-item-icon">
          {getGamingPlatformIcon(x)}
        </div>
        <div className="gaming-platform-selector-item-label">
          {getGamingPlatformLabel(x)}
        </div>
      </div>
    ));
  };

  const accept = () => {
    props.onSelected?.(Object.keys(selection) as GamingPlatform[]);
  };

  const cancel = () => {
    props.onCancel?.();
  };

  return (
    <div className="gaming-platform-selector-root">
      <ShLabel>Select platforms</ShLabel>
      <div className="gaming-platform-selector-content">
        {getAvailablePlatforms()}
      </div>

      <StickySection>
        <ShButton variant="dark" onClick={() => accept()}>
          Accept
        </ShButton>
        <ShButton onClick={() => cancel()}>Cancel</ShButton>
      </StickySection>
    </div>
  );
};

export default GamingPlatformSelector;
