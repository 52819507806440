export class ToastService {
  private static _subscriptions: ((msg: string | null) => void)[] = [];
  private static _timer: NodeJS.Timeout | null = null;
  private static _message: string | null = null;

  private static _notify() {
    this._subscriptions.forEach(sub => sub(this._message));
  }

  public static show(message: string, delay: number = 2000) {
    ToastService._message = message;
    ToastService._notify();

    if (this._timer) {
      clearTimeout(this._timer);
    }

    this._timer = setTimeout(() => {
      ToastService._message = null;
      this._timer = null;
      ToastService._notify();
    }, delay);
  }

  public static subscribe(callback: (msg: string | null) => void) {
    ToastService._subscriptions.push(callback);
    return () => {
      ToastService._subscriptions = ToastService._subscriptions.filter(
        x => x !== callback
      );
    };
  }
}
