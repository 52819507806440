import ShLabel from '@/components/ui-framework/ShLabel/ShLabel';
import { useAuthentication } from '@/hooks/use-authentication';
import { useEventFromUrlParam } from '@/hooks/use-event-from-url-param';
import { useEventTimeWindow } from '@/hooks/use-event-time-window';
import { DetailedEvent } from '@/services/api-clients/models/detailed-event';
import { FC, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import EventAttendeesSection from '../../Common/EventAttendeesSection/EventAttendeesSection';
import EventCommentsSection from '../../Common/EventCommentsSection/EventCommentsSection';
import LoadingOverlay from '../../Common/LoadingOverlay/LoadingOverlay';
import { withPageMetadata } from '../../Common/PageMetadata/PageMetadata';
import StickySection from '../../Common/StickySection/StickySection';
import { LayoutService } from '../../Layouts/MainLayout/services/layout-service';
import EventLoungeActionsSection from './components/EventLoungeActionsSection/EventLoungeActionsSection';
import './EventLoungePage.scss';

const EventLoungePage: FC = () => {
  const navigate = useNavigate();
  const auth = useAuthentication();

  const { loadingEvent, poster, event, eventId } = useEventFromUrlParam();
  const eventTimeWindow = useEventTimeWindow(event);

  useEffect(() => {
    LayoutService.setConfig({
      colorMode: 'dark',
      backgroundImage: poster,
    });
  }, [poster]);

  useEffect(() => {
    if (eventTimeWindow.inProgress === false) {
      navigate(`/events/${eventId}`);
    }
  }, [eventId, eventTimeWindow.inProgress]);

  const renderDetails = (event: DetailedEvent) => {
    return (
      <>
        <ShLabel variant="light" size="lg" centered={true}>
          {event.title} Lounge
        </ShLabel>
        <EventAttendeesSection
          mode="all-attendees"
          creator={event.createdBy}
          rsvps={event.rsvps}
        />
        <EventCommentsSection
          isClosed={!!eventTimeWindow.isClosed}
          eventId={event.id}
        />
        <StickySection variant="transparent">
          <EventLoungeActionsSection />
        </StickySection>
      </>
    );
  };

  return (
    <div className="event-lounge-page-root">
      {event && renderDetails(event)}
      <LoadingOverlay loading={loadingEvent || auth.loading} />
    </div>
  );
};

export default withPageMetadata(EventLoungePage, {
  title: 'Event Lounge',
});
