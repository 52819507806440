import ShButton from '@/components/ui-framework/ShButton/ShButton';
import ShInput from '@/components/ui-framework/ShInput/ShInput';
import { useAuthentication } from '@/hooks/use-authentication';
import { useThrottledEffect } from '@/hooks/use-throttled-effect';
import { userService } from '@/services/api-clients/user-service';
import { AuthStep } from '@/services/models/auth-step';
import { HourglassIcon, UserIcon } from 'lucide-react';
import { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from '../../Common/Header/Header';
import LoadingOverlay from '../../Common/LoadingOverlay/LoadingOverlay';
import { withPageMetadata } from '../../Common/PageMetadata/PageMetadata';
import StickySection from '../../Common/StickySection/StickySection';
import AvatarSelector from './components/AvatarSelector/AvatarSelector';
import './RegisterPage.scss';

const RegisterPage: FC = () => {
  const navigate = useNavigate();
  const auth = useAuthentication();
  const [avatar, setAvatar] = useState(
    'https://static.vecteezy.com/system/resources/thumbnails/009/292/244/small/default-avatar-icon-of-social-media-user-vector.jpg'
  );
  const [username, setUsername] = useState('');
  const [checkingUsername, setCheckingUsername] = useState(false);
  const [usernameAvailable, setUsernameAvailable] = useState(true);

  useEffect(() => {
    if (auth.step !== null && auth.step !== AuthStep.WaitingForRegistration) {
      navigate('/');
    }
  }, [auth]);

  useThrottledEffect(
    () => {
      (async () => {
        try {
          setCheckingUsername(true);
          setUsernameAvailable(true);
          const available = await userService.isUsernameAvailable(username);
          setUsernameAvailable(available);
        } finally {
          setCheckingUsername(false);
        }
      })();
    },
    300,
    [username]
  );

  const getIcon = () => (checkingUsername ? <HourglassIcon /> : <UserIcon />);

  return (
    <div className="register-page-root">
      {auth.errorMessage && (
        <div className="register-page-error-message">{auth.errorMessage}</div>
      )}
      {!usernameAvailable && (
        <div className="register-page-error-message">
          Username is not available
        </div>
      )}
      <Header
        title="Welcome to Sesh!"
        subtitle="How you want to be addressed in Sesh?"
      />
      <div className="register-page-content">
        <ShInput
          placeholder="Username"
          value={username}
          leftSection={getIcon()}
          onChange={e => setUsername(e.target.value)}
        />
        <div className="register-page-avatar">
          <AvatarSelector value={avatar} onSelected={x => setAvatar(x)} />
        </div>
        <StickySection>
          <ShButton
            variant="dark"
            upperCase={true}
            disabled={!usernameAvailable || checkingUsername || !username}
            onClick={() =>
              auth.login.registerUser({
                avatar,
                username,
              })
            }
          >
            Save Profile
          </ShButton>
        </StickySection>
      </div>
      <LoadingOverlay loading={auth.loading} />
    </div>
  );
};

export default withPageMetadata(RegisterPage, {
  title: 'Register',
});
