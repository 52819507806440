import StickySection from '@/components/Kernel/Common/StickySection/StickySection';
import ShButton from '@/components/ui-framework/ShButton/ShButton';
import ShLabel from '@/components/ui-framework/ShLabel/ShLabel';
import { CameraIcon } from 'lucide-react';
import { FC, useState } from 'react';
import './AvatarSelector.scss';

interface Props {
  value?: string;
  onSelected?: (avatar: string) => void;
}

const AvailableAvatars = [
  '/images/avatars/rick1.png',
  '/images/avatars/rick2.png',
  '/images/avatars/rick3.png',
  '/images/avatars/rick4.png',
  '/images/avatars/rick5.png',
  '/images/avatars/rick6.png',
  '/images/avatars/rick7.png',
  '/images/avatars/rick8.png',
  '/images/avatars/morty1.png',
  '/images/avatars/morty2.png',
  '/images/avatars/morty3.png',
  '/images/avatars/morty4.png',
  '/images/avatars/morty5.png',
  '/images/avatars/morty6.png',
  '/images/avatars/morty7.png',
  '/images/avatars/morty8.png',
];

const AvatarSelector: FC<Props> = props => {
  const [isMenuOpen, openMenu] = useState(false);
  const [selected, setSelected] = useState(props.value || AvailableAvatars[0]);
  const [highlited, setHighlited] = useState(AvailableAvatars[0]);

  const accept = () => {
    setSelected(highlited);
    props.onSelected?.(highlited);
    openMenu(false);
  };

  const cancel = () => {
    openMenu(false);
  };

  const open = () => {
    openMenu(true);
    setHighlited(selected);
  };

  const renderControl = () => {
    return (
      <div className="avatar-selector-control">
        <div
          className="avatar-selector-image"
          style={{
            backgroundImage: `url(${selected})`,
          }}
          onClick={() => open()}
        >
          <div className="avatar-selector-button">
            <CameraIcon />
          </div>
        </div>
      </div>
    );
  };

  const renderSelectionMenu = () => {
    return (
      <div className="avatar-selector-menu">
        <ShLabel>Select your avatar</ShLabel>
        <div className="avatar-selector-menu-content">
          {AvailableAvatars.map((x, i) => (
            <div
              key={i}
              className={
                'avatar-selector-menu-item ' +
                (highlited === x ? 'selected' : '')
              }
              onClick={() => setHighlited(x)}
            >
              <div
                className="avatar-selector-menu-item-image"
                style={{
                  backgroundImage: `url(${x})`,
                }}
              />
            </div>
          ))}
        </div>
        <StickySection>
          <ShButton
            variant="dark"
            disabled={!highlited}
            onClick={() => accept()}
          >
            Select
          </ShButton>
          <ShButton onClick={() => cancel()}>Cancel</ShButton>
        </StickySection>
      </div>
    );
  };

  return (
    <div className="avatar-selector-root">
      {!isMenuOpen && renderControl()}
      {isMenuOpen && renderSelectionMenu()}
    </div>
  );
};

export default AvatarSelector;
