import ShDivider from '@/components/ui-framework/ShDivider/ShDivider';
import { ToastService } from '@/components/ui-framework/ShToast/services/toast-service';
import { useAuthentication } from '@/hooks/use-authentication';
import { useEventFromUrlParam } from '@/hooks/use-event-from-url-param';
import { useEventRsvpsLiveUpdates } from '@/hooks/use-event-rsvps-live-updates';
import { useEventTimeWindow } from '@/hooks/use-event-time-window';
import { analyticsService } from '@/services/analytics-service';
import { eventService } from '@/services/api-clients/event-service';
import { DetailedEvent } from '@/services/api-clients/models/detailed-event';
import { DetailedRsvp } from '@/services/api-clients/models/detailed-rsvp';
import { RsvpStatus } from '@/services/api-clients/models/rsvp-status';
import { getRouteWithRedirect } from '@/services/route-redirection';
import {
  copyEventLink,
  isShareSupported,
  shareEvent,
} from '@/services/share-service';
import { TrackingEvent } from '@/services/tracking-event';
import { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import EventAttendeesSection from '../../Common/EventAttendeesSection/EventAttendeesSection';
import EventCommentsSection from '../../Common/EventCommentsSection/EventCommentsSection';
import EventPoster from '../../Common/EventPoster/EventPoster';
import LoadingOverlay from '../../Common/LoadingOverlay/LoadingOverlay';
import { withPageMetadata } from '../../Common/PageMetadata/PageMetadata';
import StickySection from '../../Common/StickySection/StickySection';
import { LayoutService } from '../../Layouts/MainLayout/services/layout-service';
import EventCalendarSection from './components/EventCalendarSection/EventCalendarSection';
import EventDetailsActionsSection from './components/EventDetailsActionsSection/EventDetailsActionsSection';
import EventGameSection from './components/EventGameSection/EventGameSection';
import EventHostSection from './components/EventHostSection/EventHostSection';
import EventTimeWindowIndicator from './components/EventTimeWindowIndicator/EventTimeWindowIndicator';
import './EventDetailsPage.scss';

const EventDetailsPage: FC = () => {
  const navigate = useNavigate();
  const auth = useAuthentication();

  const [loading, setLoading] = useState(false);
  const [rsvps, setRsvps] = useState<DetailedRsvp[]>([]);
  const { loadingEvent, poster, event, eventId } = useEventFromUrlParam();
  const eventTimeWindow = useEventTimeWindow(event);
  useEventRsvpsLiveUpdates(x => setRsvps(x), eventId);

  useEffect(() => {
    LayoutService.setConfig({
      colorMode: 'dark',
      backgroundImage: poster,
    });
  }, [poster]);

  useEffect(() => {
    if (eventTimeWindow.inProgress) {
      navigate(`/events/${eventId}/lounge`);
    }
  }, [eventId, eventTimeWindow.inProgress]);

  const rsvpOrLogin = async (event: DetailedEvent, rsvp: RsvpStatus) => {
    if (!auth.user) {
      const route = getRouteWithRedirect('/login');
      navigate(route);
      return;
    }
    try {
      setLoading(true);
      await eventService.setRsvp(event.id, rsvp);
      analyticsService.trackEvent(TrackingEvent.RSVP, {
        rsvp,
        event,
      });
    } finally {
      setLoading(false);
    }
  };

  const invite = async (event: DetailedEvent) => {
    if (isShareSupported()) {
      await shareEvent(event);
    } else {
      await copyEventLink(event.id);
      ToastService.show('Event link copied!');
    }
  };

  const goToEditor = (event: DetailedEvent) => {
    navigate(`/events/${event.id}/edit`);
  };

  const renderDetails = (event: DetailedEvent) => {
    return (
      <>
        <EventPoster
          title={event.title}
          gifUrl={event.gifUrl}
          imageUrl={poster}
          variant="light"
        />
        <EventCalendarSection event={event} />
        <EventGameSection event={event} />
        <EventHostSection event={event} />
        <ShDivider variant="light" />
        <EventAttendeesSection
          mode={eventTimeWindow.isClosed ? 'all-attendees' : 'going-and-maybe'}
          rsvps={rsvps}
        />
        <EventCommentsSection
          isClosed={!!eventTimeWindow.isClosed}
          eventId={event.id}
        />
        <StickySection variant="transparent">
          <EventTimeWindowIndicator timeWindow={eventTimeWindow} />
          {!eventTimeWindow.hasStarted && (
            <EventDetailsActionsSection
              rsvps={rsvps}
              userId={auth.user?.id}
              creatorUserId={event.createdBy.id}
              onEdit={() => goToEditor(event)}
              onInvite={() => invite(event)}
              onRsvp={rsvp => rsvpOrLogin(event, rsvp)}
            />
          )}
        </StickySection>
      </>
    );
  };

  return (
    <div className="event-details-page-root">
      {event && renderDetails(event)}
      <LoadingOverlay loading={loading || loadingEvent || auth.loading} />
    </div>
  );
};

export default withPageMetadata(EventDetailsPage, {
  title: 'Event Details',
});
