import { eventService } from '@/services/api-clients/event-service';
import { DetailedEvent } from '@/services/api-clients/models/detailed-event';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

export const useEventFromUrlParam = () => {
  const navigate = useNavigate();
  const [poster, setPoster] = useState('');
  const { eventId } = useParams<'eventId'>();
  const [loadingEvent, setLoadingEvent] = useState(true);
  const [event, setEvent] = useState<DetailedEvent | null>(null);

  useEffect(() => {
    (async () => {
      if (!eventId) {
        return;
      }

      try {
        setLoadingEvent(true);
        const event = await eventService.getById(eventId);

        if (event) {
          setEvent(event);
          setPoster(event.media?.detailsPage || event.game.imageUrl);
        } else {
          navigate('/notfound');
        }
      } finally {
        setLoadingEvent(false);
      }
    })();
  }, [eventId]);

  return {
    event,
    eventId,
    poster,
    loadingEvent,
  };
};
