import { RecaptchaHandler } from '@/components/Kernel/Common/Recaptcha/services/recaptcha-handler';
import { User } from '@/services/api-clients/models/user';
import { UserRegistrationRequest } from '@/services/api-clients/models/user-registration-request';
import { userService } from '@/services/api-clients/user-service';
import { auth } from '@/services/firebase';
import { AuthStep } from '@/services/models/auth-step';
import { getRedirectInRoute } from '@/services/route-redirection';
import { SessionManager } from '@/services/session-manager';
import { signInWithPhoneNumber } from 'firebase/auth';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

export interface AuthResult {
  loading: boolean;
  user: User | null;
  step: AuthStep | null;
  errorMessage: string | null;
  login: {
    start: (phoneNumber: string) => Promise<boolean>;
    registerUser: (
      userRegistration: UserRegistrationRequest
    ) => Promise<boolean>;
    complete: (code: string) => Promise<void>;
  };
  logout: () => void;
}

export const useAuthentication = (): AuthResult => {
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState<User | null>(null);
  const [step, setStep] = useState<AuthStep | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    setUser(SessionManager.getUser());
    const unsubsUser = SessionManager.onUserChange(u => setUser(u));

    setStep(SessionManager.getAuthStep());
    const ubsubsStep = SessionManager.onAuthStepChange(s => setStep(s));

    return () => {
      unsubsUser();
      ubsubsStep();
    };
  }, []);

  const _mapAuthErrorMessage = (error: Error): string | null => {
    if (error.name === 'FirebaseError') {
      const firebaseError = error as Error & { code: string };

      if (firebaseError.code === 'auth/invalid-verification-code') {
        return 'Invalid verification code, please try again.';
      }
      if (firebaseError.code === 'auth/invalid-phone-number') {
        return 'Invalid phone number. Please enter a valid phone number.';
      }

      return error.message;
    }
    return null;
  };

  const _runAsync = async (fn: () => Promise<void>): Promise<boolean> => {
    setErrorMessage(null);
    setLoading(true);

    try {
      await fn();
      return true;
    } catch (err: any) {
      SessionManager.setAuthStep(AuthStep.Failed);
      const mappedErrorMessage = _mapAuthErrorMessage(err);
      if (mappedErrorMessage) {
        setErrorMessage(mappedErrorMessage);
        return false;
      } else {
        throw err;
      }
    } finally {
      setLoading(false);
    }
  };

  const _redirect = () => {
    const redirect = getRedirectInRoute('/');
    navigate(redirect);
  };

  const start = async (phoneNumber: string) => {
    return _runAsync(async () => {
      const confirmationResult = await signInWithPhoneNumber(
        auth,
        phoneNumber,
        RecaptchaHandler.getVerifier()
      );
      RecaptchaHandler.setConfirmation(confirmationResult);
    });
  };

  const complete = async (code: string) => {
    await _runAsync(async () => {
      await RecaptchaHandler.getConfirmation().confirm(code);
      const user = await userService.getMe();
      SessionManager.setUser(user);
      SessionManager.setAuthStep(AuthStep.Completed);
      _redirect();
    });
  };

  const registerUser = async (
    userRegistration: UserRegistrationRequest
  ): Promise<boolean> => {
    return _runAsync(async () => {
      const user = await userService.register(userRegistration);
      SessionManager.setUser(user);
      SessionManager.setAuthStep(AuthStep.Completed);
      _redirect();
    });
  };

  const logout = (): Promise<void> => {
    SessionManager.clear();
    return auth.signOut();
  };

  return {
    user,
    step,
    errorMessage,
    loading,
    login: {
      start,
      registerUser,
      complete,
    },
    logout,
  };
};
