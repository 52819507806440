import ShAvatar from '@/components/ui-framework/ShAvatar/ShAvatar';
import { analyticsService } from '@/services/analytics-service';
import { DetailedEvent } from '@/services/api-clients/models/detailed-event';
import { TrackingEvent } from '@/services/tracking-event';
import dayjs from 'dayjs';
import { Gamepad2Icon } from 'lucide-react';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import './EventCard.scss';

interface Props {
  event: DetailedEvent;
  getPosterUrl: (event: DetailedEvent) => string;
}

const EventCard: FC<Props> = ({ event, getPosterUrl }) => {
  const navigate = useNavigate();
  const dateFormatter = dayjs(event.eventAt);
  return (
    <div
      className="event-card-root"
      key={event.id}
      onClick={() => {
        analyticsService.trackEvent(TrackingEvent.CLICK_ON_EVENT, {
          event: event,
        });
        navigate(`/events/${event.id}`);
      }}
    >
      <div
        className="event-poster"
        style={{
          backgroundImage: 'url(' + getPosterUrl(event) + ')',
        }}
      />
      <div className="event-date">
        {dateFormatter.format('ddd MM/DD • hh:mma')}
      </div>
      <div className="event-info-layout">
        <div className="event-title">{event.title}</div>
        <div className="event-subtitle">
          Event hosted by
          <ShAvatar value={event.createdBy.avatar} size="sm" />
          <strong>@{event.createdBy.username}</strong>
        </div>
        <div className="event-game">
          <Gamepad2Icon size="1.5rem" />
          {event.game.title}
        </div>
      </div>
    </div>
  );
};

export default EventCard;
