import ShAvatar from '@/components/ui-framework/ShAvatar/ShAvatar';
import { DetailedRsvp } from '@/services/api-clients/models/detailed-rsvp';
import { RsvpStatus } from '@/services/api-clients/models/rsvp-status';
import { UserOverview } from '@/services/api-clients/models/user-overview.mjs';
import { UsersIcon } from 'lucide-react';
import { FC } from 'react';
import './EventAttendeesSection.scss';

interface Props {
  mode: 'going-and-maybe' | 'all-attendees';
  rsvps: DetailedRsvp[];
  creator?: UserOverview;
}

const EventAttendeesSection: FC<Props> = props => {
  const getConfirmedAttendees = () => {
    const going = props.rsvps.filter(x => x.status === RsvpStatus.Going);
    const maybe = props.rsvps.filter(x => x.status === RsvpStatus.Maybe);
    return {
      going,
      maybe,
    };
  };

  const { going, maybe } = getConfirmedAttendees();

  return (
    <div className="event-attendees-section-root">
      {props.mode === 'going-and-maybe' && (
        <div className="event-attendees-section-count">
          <UsersIcon />
          <div>{going.length} Going</div>
          <div>{maybe.length} Maybe</div>
        </div>
      )}
      {props.mode === 'all-attendees' && (
        <div className="event-attendees-section-count">
          <UsersIcon />
          <div>
            {going.length + maybe.length + (props.creator ? 1 : 0)} Attendees
          </div>
        </div>
      )}
      <div className="event-attendees-section-avatars">
        {props.creator && (
          <div>
            <ShAvatar
              value={props.creator.avatar}
              name={props.creator.username}
              size="md"
            />
          </div>
        )}
        {[...going, ...maybe].map((rsvp, i) => (
          <div key={i}>
            <ShAvatar value={rsvp.avatar} name={rsvp.username} size="md" />
          </div>
        ))}
      </div>
    </div>
  );
};

export default EventAttendeesSection;
