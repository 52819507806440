import ShButton from '@/components/ui-framework/ShButton/ShButton';
import ShIconButton from '@/components/ui-framework/ShIconButton/ShIconButton';
import { DetailedRsvp } from '@/services/api-clients/models/detailed-rsvp';
import { RsvpStatus } from '@/services/api-clients/models/rsvp-status';
import {
  CloudRainWindIcon,
  MehIcon,
  PencilIcon,
  ShareIcon,
  ThumbsUpIcon,
  UserPlusIcon,
} from 'lucide-react';
import { FC } from 'react';
import './EventDetailsActionsSection.scss';

interface Props {
  userId?: string;
  rsvps: DetailedRsvp[];
  creatorUserId: string;
  onEdit: () => void;
  onInvite: () => void;
  onRsvp: (status: RsvpStatus) => void;
}

const EventDetailsActionsSection: FC<Props> = props => {
  const getCurrentRsvpStatus = (): RsvpStatus | null => {
    return props.rsvps.find(x => x.userId === props.userId)?.status || null;
  };

  const currentRsvp = getCurrentRsvpStatus();

  const getUserActions = () => {
    return (
      <>
        <div className="event-details-actions-section-item">
          <ShIconButton
            variant={currentRsvp === RsvpStatus.Going ? 'accent' : 'solid'}
            size="lg"
            onClick={() => props.onRsvp(RsvpStatus.Going)}
          >
            <ThumbsUpIcon />
          </ShIconButton>
          <div className="event-details-actions-section-item-title">Going</div>
        </div>
        <div className="event-details-actions-section-item">
          <ShIconButton
            size="lg"
            variant={currentRsvp === RsvpStatus.Maybe ? 'accent' : 'solid'}
            onClick={() => props.onRsvp(RsvpStatus.Maybe)}
          >
            <MehIcon />
          </ShIconButton>
          <div className="event-details-actions-section-item-title">Maybe</div>
        </div>
        <div className="event-details-actions-section-item">
          <ShIconButton
            size="lg"
            variant={currentRsvp === RsvpStatus.Raincheck ? 'accent' : 'solid'}
            onClick={() => props.onRsvp(RsvpStatus.Raincheck)}
          >
            <CloudRainWindIcon />
          </ShIconButton>
          <div className="event-details-actions-section-item-title">
            Raincheck
          </div>
        </div>
        <div className="event-details-actions-section-item">
          <ShIconButton
            size="lg"
            variant="solid"
            onClick={() => props.onInvite()}
          >
            <ShareIcon />
          </ShIconButton>
          <div className="event-details-actions-section-item-title">Share</div>
        </div>
      </>
    );
  };

  const getOwnerActions = () => {
    return (
      <>
        <ShButton variant="accent" onClick={() => props.onInvite()}>
          <UserPlusIcon /> Invite
        </ShButton>
        <ShButton onClick={() => props.onEdit()}>
          <PencilIcon /> Edit
        </ShButton>
      </>
    );
  };

  return (
    <div className="event-details-actions-section-root">
      {props.userId === props.creatorUserId && getOwnerActions()}
      {props.userId !== props.creatorUserId && getUserActions()}
    </div>
  );
};

export default EventDetailsActionsSection;
