import { BaseService } from './base-service';
import {
  bearerTokenApiClient,
  BearerTokenApiClient,
} from './bearer-token-api-client';
import { User } from './models/user';
import { UserRegistrationRequest } from './models/user-registration-request';

export class UserService extends BaseService {
  public constructor(apiClient: BearerTokenApiClient) {
    super(apiClient, 'users');
  }

  public async isUsernameAvailable(username: string): Promise<boolean> {
    const url = this.url(`/available-usernames?username=${username}`);
    const result = await this.apiClient.get<{ available: boolean }>(url);
    return result.available;
  }

  public async getMe(): Promise<User> {
    const url = this.url('/me');
    return await this.apiClient.get(url);
  }

  public async register(
    userRegistrationRequest: UserRegistrationRequest
  ): Promise<User> {
    const url = this.url('/');
    return this.apiClient.post<UserRegistrationRequest, User>(
      url,
      userRegistrationRequest
    );
  }

  public updateAvatar(avatar: string): Promise<User> {
    const url = this.url('/me/avatar');
    return this.apiClient.put<{ avatar: string }, User>(url, { avatar });
  }
}

export const userService = new UserService(bearerTokenApiClient);
