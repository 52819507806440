import { FC } from 'react';
import './ShDivider.scss';

interface Props {
  variant?: 'light' | 'dark';
}

const ShDivider: FC<Props> = props => {
  return <div className={`sh-divider ${props.variant || 'light'}`} />;
};

export default ShDivider;
