export const configuration = {
  cedApiKey: "7bb10d204188283d1df62a5c3c0d39be1442b637d1ad1933270be4df85a03aa56fe3153f5a749d6c0d2dc2edf99bd712e69b",
  environment: "production",
  version: "1.0.9",
  coreApi: {
    url: "https://sesh-prod-9049541dc17c.herokuapp.com",
  },
  eventsApi: {
    url: "https://7vot3mpklzwwiuivomowfei6jy0nmowk.lambda-url.us-east-1.on.aws/api",
    shareUrl: "https://share.sesh.xyz",
  },
  giphy: {
    apiKey: "ZbUMhI4FOSqrdkfIY0s8hJDdSr8OVbP2",
  },
  juneAnalytics: {
    writeKey: "4Q7oGOa103VZXI9z",
  },
};