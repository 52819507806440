import ShLabel from '@/components/ui-framework/ShLabel/ShLabel';
import { FC } from 'react';
import './Header.scss';

interface Props {
  title: string;
  subtitle?: string;
  centered?: boolean;
  variant?: 'dark' | 'light';
}

const Header: FC<Props> = props => {
  return (
    <>
      <div className="header-root">
        <ShLabel size="lg" centered={props.centered} variant={props.variant}>
          {props.title}
        </ShLabel>
        {props.subtitle && (
          <ShLabel
            secondary={true}
            centered={props.centered}
            variant={props.variant}
          >
            {props.subtitle}
          </ShLabel>
        )}
      </div>
    </>
  );
};

export default Header;
