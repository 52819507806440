import { auth } from '@/services/firebase';
import { ConfirmationResult, RecaptchaVerifier } from 'firebase/auth';

export class RecaptchaHandler {
  private static _verifier: RecaptchaVerifier | null = null;
  private static _confirmation: ConfirmationResult | null = null;

  public static initialize(containerId: string): void {
    if (this._verifier) {
      return;
    }

    this._verifier = new RecaptchaVerifier(auth, containerId, {
      size: 'invisible',
    });
    this._verifier.render();
  }

  public static clear(): void {
    this._verifier?.clear();
    this._verifier = null;
    this._confirmation = null;
  }

  public static getVerifier(): RecaptchaVerifier {
    if (!this._verifier) {
      throw new Error('Recaptcha is not initialized');
    }
    return this._verifier;
  }

  public static setConfirmation(confirmation: ConfirmationResult): void {
    this._confirmation = confirmation;
  }

  public static getConfirmation(): ConfirmationResult {
    if (!this._confirmation) {
      throw new Error('Recaptcha is not initialized');
    }
    return this._confirmation;
  }
}
