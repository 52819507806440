import { EventCreationData } from '../models/event-creation-data';

export class EventCreationDataFlashState {
  private static _data: EventCreationData | null = null;

  public static unload(): EventCreationData | null {
    const data = this._data;
    this._data = null;
    return data;
  }

  public static store(data: EventCreationData | null) {
    return (this._data = data);
  }
}
